<template>
  <v-btn text :href="directionsLink" class="get-directions" target="blank">
    <v-icon left color="primary">
      mdi-directions
    </v-icon>
    Get directions
  </v-btn>
</template>
<script>
export default {
  props: ["lat", "lng"],
  computed: {
    directionsLink() {
      return `https://www.google.com/maps/dir//${this.lat},${this.lng}`;
    }
  }
};
</script>
<style lang="scss">
  .get-directions {
    font-size: 14px;
  }
</style>