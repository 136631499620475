<template>
  <div class="operator-listing" v-if="operator">
    <div class="operator-listing__head">
      <div
        class="map-operator-side__images"
        v-if="operator.OperatorData.operatorImages"
      >
        <v-carousel
          v-if="
            operator.OperatorData.operatorImages &&
              operator.OperatorData.operatorImages.length > 1
          "
          :cycle="false"
          height="224"
          show-arrows-on-hover
          hide-delimiters
        >
          <v-carousel-item
            v-for="slide in operator.OperatorData.operatorImages"
            :key="slide.mediaItemId"
            :src="slide.sourceUrl"
          >
          </v-carousel-item>
        </v-carousel>
        <v-img
          v-else
          v-for="slide in operator.OperatorData.operatorImages"
          :key="slide.mediaItemId"
          :src="slide.sourceUrl"
          height="224"
        />
      </div>
      <div v-else>

        <v-img
          src="https://wisdom-site-visitmudgeeregion-com-au.s3-ap-southeast-2.amazonaws.com/default-operator-image.jpg"
          height="224"
        />
      </div>
    </div>
    <div class="operator-listing__body">
      <v-container>
        <a :href="baseUrl + '/operator/' + operator.slug">
          <h2
            class="map-operator-side__title text-center"
            v-html="operator.title"
          />
        </a>
        <div
          v-if="operatorCategories"
          :class="operatorCategoryType"
          class="map-operator-side__categories text-center"
        >
          <div
            class="map-operator-side__category"
            v-for="(category, i) in operatorCategories"
            :key="i"
          >
            {{ category }}
          </div>
        </div>
        <div v-if="operatorStarRating">
          <div class="map-operator-side__star-rating">
            <span class="map-operator-side__star-rating-label">
              {{ operatorStarRating }}
              star
            </span>
            <v-rating
              color="red"
              dense
              half-increments
              readonly
              empty-icon=""
              v-model="operatorStarRating"
            ></v-rating>
          </div>
        </div>
        <div v-if="getOperatorCategory(operator)">
          <div class="map-operator-side__category text-center">
            <span
              class="map-operator-side__category-label"
              :class="'text-color--'+getOperatorCategory(operator).slug"
            >
              {{ getOperatorCategory(operator).name }}
            </span>
          </div>
        </div>
      </v-container>
    </div>
    <div class="operator-listing__footer">
      <v-container>
        <div class="text-center mt-2 mb-2">
          <v-btn
            block
            :color="getOperatorCategoryColour(operator)"
            class="map-operator-side__link font-weight-bold white--text"
            depressed
            :href="baseUrl + '/operator/' + operator.slug"
            >View details</v-btn
          >
        </div>
        <div
          class="operator-button-group"
          v-if="operator.OperatorData.operatorMapMarker"
        >
          <add-to-trip-planner class="mb-2" :id="operator.operatorId" />
          <get-directions
            class="mb-2"
            :lat="operator.OperatorData.operatorMapMarker.latitude"
            :lng="operator.OperatorData.operatorMapMarker.longitude"
          />
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import AddToTripPlanner from "./AddToTripPlanner";
import GetDirections from "./GetDirections";
import { mapState } from "vuex";

export default {
  components: {
    AddToTripPlanner,
    GetDirections
  },
  props: ["operator"],
  computed: {
    ...mapState(["baseUrl"]),
    operatorCategoryType() {
      return this.operator.operatorData.operatorType;
    },
    operatorCategories() {
      if (
        this.operator.operatorData &&
        this.operator.operatorData.operatorType
      ) {
        if (this.operatorCategoryType === "placesToStayGroup") {
          return this.operator.accommodationTypes.nodes.map(category => {
            return category.name;
          });
        }
        if (this.operatorCategoryType === "eatAndDrinkGroup") {
          return this.operator.foodAndDrinkTypes.nodes.map(category => {
            return category.name;
          });
        }
      }
      return null;
    },
    operatorImages() {
      let images = Array();
      if (
        this.operator.OperatorData &&
        this.operator.OperatorData.operatorImages
      ) {
        images.push(...this.operator.OperatorData.operatorImages);
      }
      return images; // TODO: MG duplicate first image?
    },
    operatorStarRating() {
      if (
        this.operator.operatorData &&
        this.operator.operatorData.placesToStayGroup
      )
        return parseInt(
          this.operator.operatorData.placesToStayGroup.operatorStarRating
        );

      return null;
    }
  },
  methods: {
    getOperatorCategory(operator) {
      return this.$store.state.categories.filter(category => {
        return operator.operatorCategories.nodes.some(operatorCategory => {
          return operatorCategory.databaseId === category.databaseId
        })
      })[0]

    },
    getOperatorCategoryColour(operator) {
      let category = this.getOperatorCategory(operator)
      if(category)
        return category.categoryColour.colour

      return null
    }

  }
};
</script>
<style lang="scss">
#app .theme--light.v-btn {
  font-size: inherit;
}
.operator-button-group {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    font-size: 0.65em;
  }
}

.map-operator-side {
  &__title {
    margin: 1em 0 0.8em 0;
    color: #353535;
  }

  &__categories {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8em;
    padding-bottom: 1em;
    line-height: 1.1em;
    // overflow: auto;
    // white-space: nowrap;
  }

  &__category {
    display: inline-block;
    font-size: 1em;

    &:not(:last-child)::after {
      content: "•";
      margin-left: 0.1em;
      margin-right: 0.4em;
    }
  }

  &__star-rating {
    display: flex;
  }

  &__star-rating-label {
    line-height: 1.9em;
  }

  @media screen and (min-width: 768px) {
    &__title {
      height: 2em;
    }
  }
}
.eatAndDrinkGroup {
  color: #f5685c;
}

.placesToStayGroup {
  color: #24b4e9;
}


#app .map-operator-side__title {
  font-size: 1.75em;
  line-height: 100%!important;
  margin-bottom: 0.25em;
}

.operator-listing {
 &__head {
   position: relative;
 }
}

@media screen and (min-width: 768px) {
  .operator-listing {
    $operator-listing: 490;
    $operator-listing-head: 224;
    $operator-listing-footer: 112;
    height: $operator-listing + px;

    &__head {
      height: $operator-listing-head + px;
      position: relative;
    }

    &__body {
      height: (
          $operator-listing - $operator-listing-head - $operator-listing-footer
        ) + px;
    }

    &__footer {
      height: $operator-listing-footer + px;
    }
  }
}
</style>