<template>
  <v-app class="atdw-app">
    <!-- <v-btn
      color="primary"
      class="trip-planner-button"
      dark
      x-large
      v-if="!sidebarOpen"
      @click="toggleTripPlanner()"
    >
      {{ tripPlannerButton }}
    </v-btn> -->
    <v-content>
      <Mobile />
      <v-btn
        color="secondary"
        class="filter-button"
        dark
        x-large
        v-if="!sidebarOpen"
        @click="toggleSidebar()"
      >
        Filter your options
      </v-btn>
    </v-content>
  </v-app>
</template>

<script>
import Mobile from "./components/Mobile";
import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Mobile
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["toggleSidebar"]),
    toggleTripPlanner() {
      this.$store.commit("tripPlannerActive");
    }
  },
  computed: {
    ...mapState(["sidebarOpen"]),
    isMobile: {
      get() {
        return this.$store.state.isMobile;
      },
      set() {
        return false;
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    tripPlannerButton() {
      if (this.tripPlannerActive !== true) return "My trip planner";

      return "Show directory";
    },
    // styleObject() {
    //   let backgroundColour = this.$vuetify.theme.themes[this.theme].background;
    //   if (this.$store.state.activeParentType === "itineraries") {
    //     backgroundColour = "#FFF";
    //   }

    //   return {
    //     // background: backgroundColour
    //     background: backgroundColour
    //   };
    // }
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.selected,
      () => {
        if(this.$store.state.activeView === "map")
          this.$store.commit("incrementGoogleMapStateKey");
      },
      {
        deep: true
      }
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  mounted() {
    // window.state = {};
    // window.trip_planner = "true"
    
    if (window.trip_planner === "true") {
      this.$store.commit("tripPlannerActive", true);
    }

    let parent_type =
      window.state && window.state.parent_type
        ? window.state.parent_type
        : null;
    if (parent_type) this.$store.commit("activeParentType", parent_type);

    // window.pageInfo = {};
    // window.pageInfo.site_url = "https://dw.wisdom.com.au";
    this.$store.commit("setBaseUrl");
    this.$store.commit("setCacheKeys");
    // if (window.pageInfo && window.pageInfo.site_url) {
    //   this.$store.commit("setBaseUrl", window.pageInfo.site_url);
    // }
    // window.favouritesData = {};
    // window.favouritesData["ids"] = JSON.stringify([484, 494]);

    let operatorIds = [];
    if (window.state && window.state.trip_planner) {
      operatorIds = JSON.parse(window.window.state.trip_planner);
    } else if (localStorage.getItem("favourites_ids") !== null) {
      operatorIds = JSON.parse(localStorage.getItem("favourites_ids"));
    }

    // this.$store.dispatch("getState");
    this.$store.dispatch("getAccessible");
    this.$store.dispatch("getOperatorCategories");
    this.$store.dispatch("getLocations");
    this.$store.dispatch("getItinerariesData");
    this.$store.dispatch("getTrailsData");
    this.$store.dispatch("getInterests");

    // window.active_region = "greater-sydney"

    if (operatorIds.length > 0) {
      operatorIds.forEach(id => {
        // this.$store.commit("getTripPlannerData", id);
        this.$store.commit("tripPlannerToggle", id);
      });
    }

    if(this.$store.getters.isMobile === true) {
      this.$store.commit('toggleSidebar');
    }
  }
};
</script>

<style lang="scss">
body.has-operator-app {
    scroll-snap-type: y proximity;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-padding-top: 100px;
    scroll-padding-bottom: 200px;
}

#app {
  margin: 0;
  height: 100%;
  font-size: 16px;

  h2 {
    font-size: 20px;
    line-height: 140%;
    margin: 0;
  }

  h3 {
    font-size: 18px;
  }

  input[type="text"] {
    border: initial;
  }
}

.wrap {

  position: relative;
  max-width: initial;
  padding: 0;
}

.v-application {
  font-family: inherit !important;
  line-height: inherit !important;
}

.v-application--wrap {
  @media screen and (max-width: 640px) {
    margin-bottom: 58px;
  }
}

.map {
  height: 800px;
  position: relative;
}

.v-btn {
  font-family: inherit;
}

.v-btn.filter-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  z-index: 2;
  font-size: inherit;
}
.v-btn.trip-planner-button {
  position: fixed;
  bottom: 0;
  width: 50%;
  left: 0;
  z-index: 2;
}

.font-weight-normal {
  font-weight: 400;
}
.atdw-app {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: unset;
    line-height: unset;
  }
}
</style>