<template>
  <div>
    <v-btn
      class="from-from-trip-planner"
      v-if="currentIndex !== 0"
      text
      @click="reorder('up')"
    >
      <v-icon left color="primary" class="ml-1">
        mdi-chevron-up
      </v-icon>
    </v-btn>
    <v-btn
      class="from-from-trip-planner"
      v-if="currentIndex !== totalTripPlannerItems"
      text
      @click="reorder('down')"
    >
      <v-icon left color="primary" class="ml-1">
        mdi-chevron-down
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: ["id"],
  methods: {
    reorder(direction) {
      this.$store.commit("updateTripPlannerListingPosition", {
        direction: direction,
        id: this.id
      });
    }
  },
  computed: {
    currentIndex() {
      return this.$store.state.tripPlannerData.findIndex(x => {
        return this.id === x;
      });
    },
    totalTripPlannerItems() {
      let length = this.$store.state.tripPlannerData.length;
      return --length;
    }
  }
};
</script>