import axios from "axios";
export const actions = {
        activeParentType({commit, state}, type) {
          // state.activeParentType = type;
          commit("activeParentType", type);
          if(type === 'trails' && state.selected.trails.length < 1 && state.trailsData.length > 1) {
            // state.selected.trails
            commit("updateTrailOptions", state.trailsData[0].trailId)
          }
          commit("incrementGoogleMapStateKey");
        },
        setGoogle({ commit }, google) {
          commit("setGoogle", google);
        },
        getOperatorData({ commit, dispatch, state }, payload) {
          if(state.cache) {
            let lsoperators = sessionStorage.getItem("operators-" + state.sessionStorageKey);
            if (lsoperators) {
              commit("updateOperatorData", JSON.parse(lsoperators));
              state.isLoading = false;
              return true;
            }
          }
          let after = "";
          if (payload) {
            after = payload.after;
          }
          let taxArray = ''
          if(state.activeCategories.length > 0) {
            // state.activeCategories.forEach(category => {
            //   taxArray += `{ taxonomy: OPERATORCATEGORY, terms: "${category}"},`
            // })
          }
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query getOperators($after: String) {
                operators(first: 999, after: $after) {
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  nodes {
                    id
                    operatorId
                    title
                    slug
                    operatorCategories {
                      nodes {
                        databaseId
                      }
                    }
                    facilities {
                      nodes {
                        name
                        slug
                        databaseId
                      }
                    }
                    regionsTerms {
                      nodes {
                        slug
                        databaseId
                        name
                      }
                    }
                    townsTerms {
                      nodes {
                        name
                        databaseId
                        slug
                      }
                    }
                    interestTerms {
                      nodes {
                        name
                        databaseId
                        slug
                      }
                    }
                    OperatorData {
                      operatorMapMarker {
                        latitude
                        longitude
                        streetAddress
                      }
                      operatorImages {
                        mediaItemId
                        sourceUrl(size: MEDIUM_LARGE)
                      }
                    }
                  }
                }
              }              
              `,
              variables: {
                after: after
              }
            }
          }).then(response => {
            let operators = response.data.data.operators.nodes;
            commit("updateOperatorData", operators);
            if (response.data.data.operators.pageInfo.hasNextPage === true) {
              dispatch("getOperatorData", {
                after: response.data.data.operators.pageInfo.endCursor
              });
            } else {
              if(state.cache) {
                sessionStorage.setItem(
                  "operators-" + state.sessionStorageKey,
                  JSON.stringify(state.operatorData)
                );
              }
              state.isLoading = false;
              state.googleMapStateKey++;
            }
          });
        },
        getItinerariesData({ commit, state }) {
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              {
                itineraries {
                  nodes {
                    id
                    title
                    itineraryId
                    acfOperators {
                      operators {
                        operator {
                          ... on Operator {
                            id
                            operatorId
                            title
                          }
                        }
                      }
                    }
                    durations {
                      nodes {
                        id
                        name
                        durationId
                      }
                    }
                  }
                }
              }
              `
            }
          }).then(response => {
            if(!response.data.data) return;
            let itineraries = response.data.data.itineraries.nodes;
            let newItineraries = [];
            newItineraries = itineraries;
            commit("updateItinerariesData", newItineraries);
            let itinerary =
              window.state && window.state.itineraries
                ? parseInt(window.state.itineraries)
                : null;
            if (itinerary) {
              let foundItinerary = newItineraries.filter(newItinerary => {
                return newItinerary.itineraryId === itinerary;
              });
              commit("updateItineraryOptions", foundItinerary[0]);
              commit("activeParentType", "itineraries");
            }
    
            // this.$store.commit("updateItineraryOptions", itinerary);
          });
        },
        getTrailsData({ dispatch, commit, state }, payload) {
          let after = "";
          if (payload) {
            after = payload.after;
          }
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query GetTrails($after: String) {
                trails(first: 1, after: $after) {
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  nodes {
                    id
                    trailId
                    title
                    acfOperators {
                      operators {
                        operator {
                          ... on Operator {
                            id
                            operatorId
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
              `,
              variables: {
                after: after
              }
            }
          }).then(response => {
            if (!response.data.data) return;
            let trails = response.data.data.trails.nodes;
            let newTrails = [];
            newTrails = trails;
            commit("updateTrailsData", newTrails);
            if (response.data.data.trails.pageInfo.hasNextPage === true)
              dispatch("getTrailsData", {
                after: response.data.data.trails.pageInfo.endCursor
              });
            else dispatch("getTrailsState"); // If there's a predefined state, load it
          });
        },
        getTrailsState({ commit, state }) {
          let post_id =
            window.state && window.state.trails
              ? parseInt(window.state.trails)
              : null;
    
          if (post_id) {
            axios({
              url: `${state.baseUrl}/wp/graphql`,
              method: "post",
              data: {
                query: `
                query MyQuery($postId: Int) {
                  trailBy(trailId: $postId) {
                    trailId
                    title
                  }
                }                   
                `,
                variables: {
                  postId: post_id
                }
              }
            }).then(response => {
              let trails = response.data.data.trailBy.trailId;
              commit("updateTrailOptions", trails);
              commit("activeParentType", "trails");
            });
          }
        },
        getAccessible({ commit, state }) {
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              {
                accessibles {
                  nodes {
                    name
                    slug
                  }
                }
              }                   
              `
            }
          }).then(response => {
            if(!response.data.data) return;
            let accessible = response.data.data.accessibles.nodes;
            commit("updateAccessible", accessible);
          });
        },
        getOperatorCategories({dispatch, commit, state}) {
          if(state.cache) {
            let lscategories = sessionStorage.getItem("categories" + state.sessionStorageKey);
            if (lscategories) {
              commit("updateCategories", JSON.parse(lscategories));
              dispatch("getState"); // need to wait until categories object has been populated - see commit("updateCategories", categories);
              return true;
            }
          }
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query GetOperatorCategories {
                operatorCategories(where: {hideEmpty: true}) {
                  nodes {
                    databaseId
                    slug
                    name
                    categoryColour {
                      colour
                    }
                    hideInOperatorApp {
                      hideInOperatorApp
                    }
                    parentDatabaseId
                    children(where: {hideEmpty: true}) {
                      nodes {
                        databaseId
                        slug
                        name
                        parent {
                          node {
                            databaseId
                          }
                        }
                      }
                    }
                  }
                }
              }                
              `
            }
          }).then(response => {
            if(!response.data.data) return;
            let categories = response.data.data.operatorCategories.nodes.filter( cat => !cat.hideInOperatorApp.hideInOperatorApp && !cat.parentDatabaseId );
            commit("updateCategories", categories);
            if(state.cache) {
              sessionStorage.setItem(
                "categories" + state.sessionStorageKey,
                JSON.stringify(state.categories)
              );
            }
          }).then(() => {
            dispatch("getState"); // need to wait until categories object has been populated - see commit("updateCategories", categories);
          }).then(() => {
            if(window.pageInfo && state.tripPlannerPageId === window.pageInfo.post_id) {
              if (state.tripPlannerData.length > 0) {
                state.tripPlannerData.forEach(id => {
                  commit("getTripPlannerData", id);
                });
                state.googleMapStateKey++;
              }
            }
          })
        },
        getLocations({commit, state}) {
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query GetLocations {
                locations {
                  nodes {
                    databaseId
                    slug
                    name
                  }
                }
              }                       
              `
            }
          }).then(response => {
            if(!response.data.data) return;
            let locations = response.data.data.locations.nodes;
            commit("updateLocations", locations);
          });
        },
        getInterests({commit, state}) {
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query GetInterests {
                interestTerms(where: {hideEmpty: true}) {
                  nodes {
                    name
                    slug
                    databaseId
                  }
                }
              }                       
              `
            }
          }).then(response => {
            if(!response.data.data) return;
            let interests = response.data.data.interestTerms.nodes;
            commit("setInterests", interests);
          });
        },
    
        getState({dispatch, commit, state}) {
          // dispatch("getOperatorData");
          // return false
          // if(state.local === true) return
          let pageId = null
          if(state.local) {
            pageId = 2649
          } else {
            pageId = parseInt(window.pageInfo.post_id)
          }
          // let pageId = 1008
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query GetState {
                page( idType: DATABASE_ID, id: `+pageId+`) {
                  atdwFeed {
                    activeView
                    showAtdwFeed
                    categories {
                      databaseId
                      name
                      slug
                      parent {
                        node {
                          databaseId
                        }
                      }
                    }
                    regions {
                      databaseId
                      slug
                      name
                    }
                    towns {
                      databaseId
                      slug
                      name
                    }
                    interests {
                      databaseId
                      slug
                      name
                    }
                    itineraries {
                      ... on Itinerary {
                        databaseId
                      }
                    }
                    trail {
                      ... on Trail {
                        databaseId
                      }
                    }
                  }
                }
              }
              `
            }
          }).then(response => {
            console.error(response)
            let payload = response.data.data.page.atdwFeed
            console.error(payload)
            if(payload.activeView)
              commit("activeParentType", payload.activeView)
            if(payload.categories && payload.categories.length > 0) {
              let categoriesArray = []
              payload.categories.forEach(category => {
                if(category.parent === null) {
                  commit("toggleDirectoryCategories", category.databaseId)
                } else {
                  const {parent, ...cleanCategory} = category // remove 'parent' property from object
                  if(categoriesArray.length < 1) {
                    categoriesArray.push({
                      subcategories: [cleanCategory],
                      parentId: category.parent.node.databaseId
                    })
                  } else {
                    categoriesArray.forEach((cat) => {
                      console.warn(cat)
                      if(cat.parentId && cat.parentId === category.parent.node.databaseId) {
                        console.log('found')
                        cat.subcategories.push(cleanCategory)
                      } else {
                        console.log('here');
                        categoriesArray.push({
                          subcategories: [cleanCategory],
                          parentId: category.parent.node.databaseId
                        })
                      }
                    })
                  }
                  console.log(categoriesArray);
                }
              })
              if(categoriesArray.length > 0) {
                categoriesArray.forEach((category) => {
                  commit("updateSubcategories", category)
                })
              }
            // } else if(payload.interests) {
            //     console.warn('intersts');
            //     state.selected.directory.forEach((category) => {
            //       commit("toggleDirectoryCategories", category.databaseId)
            //       commit("updateInterests", { interests: payload.interests, parentId: category.parent })
            //     })
            }
            if(payload.regions) {
              state.selected.directory.forEach((category) => {
                commit("updateRegions", { regions: payload.regions, parentId: category.parent })
              })
            }
            if(payload.towns) {
              state.selected.directory.forEach((category) => {
                commit("updateTowns", { towns: payload.towns, parentId: category.parent })
              })
            }
            if(payload.interests) {
              if(state.activeCategories.length === 0) {
                state.categories.forEach(category => {
                  commit("toggleDirectoryCategories", category.databaseId)
                })
              }
              state.activeCategories.forEach((categoryId) => {
                commit("updateInterests", { interests: payload.interests, parentId: categoryId })
              })
            }
            if(payload.trail)
              commit("updateTrailOptions", payload.trail.databaseId);
            
            if (payload.itineraries) {
                let foundItinerary = state.itinerariesData.filter(itinerary => {
                  return itinerary.itineraryId === payload.itineraries.databaseId;
                });
                commit("updateItineraryOptions", foundItinerary[0]);
              }
          }).then(() => {
            if(!window.trip_planner !== "true") {
              dispatch("getOperatorData");
            }
          }).then(() => { // need to wait until categories object has been populated
            if(window.active_region)
              dispatch("setActiveRegion", window.active_region);
          });
        },
        setActiveRegion({commit, state}, payload) {
          let region = payload
          console.log(region)
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query getActiveRegion {
                regionTerm(id: "${region}", idType: SLUG) {
                  name
                  databaseId
                  slug
                }
              }              
              `
            }
          }).then(response => {
            state.categories.forEach(category => {
              commit("updateRegions", {
                parentId: category.databaseId,
                regions: [
                  response.data.data.regionTerm
                ]
              })
            })
          });
        }
}