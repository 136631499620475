<template>
  <div v-if="operator" class="trip-planner-operator-listing">
    <v-container>
      <div class="flex trip-planner-operator-listing-header">
        <div class="trip-planner-button-group">
          <div>
            <h2
              class="map-operator-side__index bg"
              :class="'background-color--'+getOperatorCategory(operator).slug"
            >
              <!-- :class="operator.operatorData.operatorType" -->
              {{ index }}
            </h2>
          </div>
          <div class="d-inline-flex">
            <trip-planner-reorder :id="operator.operatorId" />
            <trip-planner-remove :id="operator.operatorId" />
          </div>
        </div>
      </div>
      <h2 class="map-operator-side__title text-left" v-html="operator.title"></h2>
      <span
        class="map-operator-side__category"
        :class="'text-color--'+getOperatorCategory(operator).slug"
      >
        {{ getOperatorCategory(operator).name }}
      </span>
      <!-- <div v-if="operator.operatorData.operatorType">
        <div
          class="map-operator-side__category colour"
          :class="operator.operatorData.operatorType"
        >
          {{ category }}
        </div>
      </div> -->
    </v-container>
    <hr />
  </div>
</template>
<script>
import TripPlannerReorder from "./TripPlannerReorder";
import TripPlannerRemove from "./TripPlannerRemove";

export default {
  components: {
    TripPlannerReorder,
    TripPlannerRemove
  },
  props: ["operator"],
  methods: {
    getOperatorCategory(operator) {
      return this.$store.state.categories.filter(category => {
        return operator.operatorCategories.nodes.some(operatorCategory => {
          return operatorCategory.databaseId === category.databaseId
        })
      })[0]
    },
  },
  computed: {
    index() {
      let tripPlannerIndex = this.$store.state.tripPlannerData.findIndex(x => {
        return x == this.operator.operatorId;
      });
      return String.fromCharCode(tripPlannerIndex + 65);
    },
    category() {
      let self = this;
      return category[0].label;
    }
  }
};
</script>
<style lang="scss">
.operator-button-group {
  display: flex;
  justify-content: space-between;
  button,
  a {
    font-size: 12px;
  }
}
.map-operator-side {
  &__index {
    border-radius: 100%;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    color: white!important;
    text-align: center;
    padding-top: 0.14em;
    padding-left: 0.15em;
    font-size: 1.5em;
    margin: 0;
  }
  &__title {
    text-align: left;
    margin-bottom: 0.5em;
    margin-top: 0;
    height: initial;
  }
  &__category {
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    display: block;
  }
}
.bg {
  &.businessAndServicesGroup {
    background: #7c64b3;
  }
  &.thingsToDoGroup {
    background: #359b9e;
  }
  &.placesToStayGroup {
    background: #24b4e9;
  }
  &.eatAndDrinkGroup {
    background: #f5685c;
  }
}
.colour {
  &.businessAndServicesGroup {
    color: #7c64b3;
  }
  &.thingsToDoGroup {
    color: #359b9e;
  }
  &.placesToStayGroup {
    color: #24b4e9;
  }
  &.eatAndDrinkGroup {
    color: #f5685c;
  }
}
.trip-planner-operator-listing {
  text-align: left;

  &-header {
    justify-content: space-between;
    margin-bottom: 1em;
  }
}
.trip-planner-button-group {
  display: flex;
  justify-content: space-between;
}
</style>