<script>
// import { POINT_MARKER_ICON_CONFIG } from "@/constants/mapSettings";
import { MARKER_LABEL_CONFIG, assetPath } from "@/constants/mapSettings";
import "@google/markerclusterer";
export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    }
  },

  mounted() {
    this.setMarkers();
  },
  methods: {
    updateMarker(markerId) {
      this.setMarkers;
      return this.$store.commit("updateMarker", markerId);
    },
    setMarkers() {
      let assetPath = "https://wisdom-plugin-atdw-app.s3-ap-southeast-2.amazonaws.com";
      let image = {
        url: `${assetPath}/marker.svg`,
        size: new this.google.maps.Size(33, 39),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(16.5, 39),
        scaledSize: new this.google.maps.Size(33, 39),
        labelOrigin: new this.google.maps.Point(16.5, 16)
      };
      let markerColours = {
        'accommodation': `${assetPath}/smt/marker-orange.svg`,
        'food-wine': `${assetPath}/smt/marker-red.svg`,
        'see-do': `${assetPath}/smt/marker-blue.svg`,
        'visitor-info-centres': `${assetPath}/smt/marker-green.svg`,
      };
      if (this.marker.category) {
        image.url = markerColours[this.marker.category];
      }
      let _this = this;
      this.googleMarker = new this.google.maps.Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        icon: image,
        animation: this.google.maps.Animation.DROP,
        title: this.marker.title
      });
      this.googleMarker.addListener("click", () => {
        _this.updateMarker(this.googleMarker.marker.id);
      });

      let routeIds = this.$store.getters.routes.map(operator => {
        return operator.id;
      });
      let routeIndex = routeIds.indexOf(this.googleMarker.marker.id);
      if (routeIndex !== -1) {
        this.setLabel(String.fromCharCode(65 + routeIndex));
      }
      if (
        this.$store.state.activeParentType !== "itineraries" &&
        this.$store.state.tripPlannerActive !== true
      ) {
        let cluster = this.$store.state.googleMapMarkerCluster;
        cluster.addMarker(this.googleMarker);
      }
    },
    setLabel(text) {
      this.googleMarker.setLabel({ text: text, ...MARKER_LABEL_CONFIG });
    }
  },
  render() {
    return true;
  }
};
</script>