<template>
  <div class="google-map">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import MarkerClusterer from "@google/markerclusterer";
import { MARKER_CLUSTER_CONFIG } from "@/constants/mapSettings";

export default {
  props: {
    mapConfig: Object,
    apiKey: String
  },
  data() {
    return {
      google: null,
      map: null
    };
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    });
    this.google = googleMapApi;
    window.google = googleMapApi
    this.initializeMap();
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      if(this.$store.state.tripPlannerActive === true && this.$store.getters.operatorMarkers.length < 1) {
        this.map.setCenter({ lat: -34.75941280151619, lng: 147.29532639232437})
        this.map.setZoom(7)
      } else { // setting the bounds was overriding center (to 0,0) with no map markers
        let bounds = new this.google.maps.LatLngBounds();
        let markers = this.$store.getters.operatorMarkers;
        markers.forEach(marker => {
          bounds.extend(marker.position);
        });
        this.map.fitBounds(bounds);
      }
      let options = {
        zoomControlOptions: {
          style: "SMALL",
          position: this.google.maps.ControlPosition.RIGHT_TOP
        },
      }
      this.map.setOptions(options);
      // this.$nextTick(function() {
      if (
        this.$store.state.activeParentType !== "itineraries" &&
        this.$store.state.tripPlannerActive !== true
      ) {
        let googleMapMarkers = this.$store.getters.googleMapMarkers;
        let markerclusterer = new MarkerClusterer(
          this.map,
          googleMapMarkers,
          MARKER_CLUSTER_CONFIG
        );
        this.$store.commit("googleMapMarkerCluster", markerclusterer);
      }
      // });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.google-map {
  width: 100%;
  /* min-height: 100%; */
  height: 100%;
}
</style>
