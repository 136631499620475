<template>
  <div class="map-operator-side">
    <v-navigation-drawer
      :value="activeOperator"
      hide-overlay
      absolute
      width="400"
    >
      <!-- TODO: MG fix navigation draw close event (@input) not synced with state -->
      <map-side-back-button text="Close" @back="updateMarker(null)" />
      <operator-listing :operator="activeOperator" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import MapSideBackButton from "./MapSideBackButton";

import OperatorListing from "./OperatorListing";

// import {mapState} from 'vuex'

export default {
  data() {
    return {};
  },
  components: {
    MapSideBackButton,
    OperatorListing
  },
  computed: {
    activeOperator: {
      get() {
        return this.$store.getters.activeOperator;
      },
      set(val) {
        this.$store.commit("updateMarker", val);
      }
    },
  },
  methods: {
    updateMarker(markerId) {
      return this.$store.commit("updateMarker", markerId);
    }
  }
};
</script>

<style lang="scss">
.map-operator-side {
  max-width: 500px;

  .operator-listing__body {
    height: auto;
  }
}
</style>