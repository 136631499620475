<template>
  <div>
    <!-- <div @click="openDrawer"> -->
    <div>
      <!-- v-model="activeTab" -->
      <v-tabs
        background-color="primary"
        class="map-tabs"
        centered
        fixed-tabs
        :value="activeTab"
      >
        <!-- @change="toggleOperator()" -->
        <v-tab
          v-for="(item, index) in sidebarTabs"
          :key="index"
          :class="'map-tabs__tab map-tabs__tab--' + index"
          active-class="active"
          color="white"
          @click="setActiveParentType(item)"
          >{{ item.label }}</v-tab
        >
      </v-tabs>
    </div>
    <v-tabs-items touchless :value="activeTab">
      <v-tab-item v-for="(item, index) in sidebarTabs" :key="index">
        <keep-alive>
          <!-- v-if="!activeOperator"  -->
          <component :is="item.component"></component>
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>
    <!-- <component v-if="activeOperator" :is="'map-operator-side'"></component> -->
  </div>
</template>
<script>
import MapSideDirectory from "./MapSideDirectory";
import MapSideTrails from "./MapSideTrails";
import MapSideItineraries from "./MapSideItineraries";
import MapSideBackButton from "./MapSideBackButton";

export default {
  components: {
    MapSideDirectory,
    MapSideTrails,
    MapSideItineraries,
    MapSideBackButton
  },
  data() {
    return {
      sidebarTabs: [
        {
          title: "directory",
          label: "directory",
          component: "map-side-directory"
        },
        {
          title: "trails",
          label: "trails",
          component: "map-side-trails"
        },
        {
          title: "itineraries",
          label: "itineraries",
          component: "map-side-itineraries"
        }
      ]
    };
  },
  methods: {
    setActiveParentType(tab) {
      this.$store.dispatch("activeParentType", tab.title);
    },
    openDrawer() {
      if (!this.$store.state.sidebarOpen) this.$emit("openDrawer");
    }
  },
  computed: {
    activeTab: {
      get() {
        // return "itineraries";
        let activeParentType = this.$store.state.activeParentType;
        let tabIndex = null;
        this.sidebarTabs.forEach((x, index) => {
          if (x.title === activeParentType) tabIndex = index;
        });
        return tabIndex;
      },
      set(val) {
        this.$store.commit("activeParentType", val);
      }
    }
  }
};
</script>
<style lang="scss">
  .map-tabs__tab {
    font-size: 1em;

    &.active {
      font-weight: bold;
    }
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: white!important;
  }
</style>