<template>
  <div>
    <div v-if="!isDirectoryFilterActive" class="directory-categories">
      <v-list>
        <v-list-item
          no-action
          v-for="category in categories"
          :key="category.databaseId"
          class="text-left pa-0"
        >
          <v-list-item-avatar>
            <v-switch
              :input-value="isActiveCategory(category.databaseId)"
              @change="toggleCategory(category.databaseId)"
              :color="category.categoryColour.colour"
              :inset="true"
            ></v-switch>
              <!-- :color="category.color" -->
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="category.name"
              class="directory-parent-category__label"
              :class="{'font-weight-bold': isActiveCategory(category.databaseId)}"
              @click="setActiveDirectoryCategory(category.databaseId)"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="isActiveCategory(category.databaseId)">
            <v-btn
              text
              color="#9c9c9c"
              x-small
              @click="setActiveDirectoryCategory(category.databaseId)"
            >
              Filter
              <v-icon class="directory-parent-category__icon" right>mdi-filter-variant</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="isDirectoryFilterActive">
      <map-side-back-button @back="setDirectoryFilterActive(false)" />
      <keep-alive>
        <!-- <component :is="isDirectoryFilterActive" /> -->
        <Directory></Directory>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import MapSideBackButton from "./MapSideBackButton";
import Directory from "./Directory";
import { mapState } from "vuex";

export default {
  components: {
    MapSideBackButton,
    Directory
  },
  methods: {
    setActiveDirectoryCategory(category) {
      this.$store.commit("activeDirectoryCategory", category)
      this.setDirectoryFilterActive(true)
    },
    setDirectoryFilterActive(value) {
      this.$store.commit("isDirectoryFilterActive", value)
    },
    isActiveCategory(category) {
      let storeRoot = this.$store.state.activeCategories;
      if (typeof storeRoot !== "undefined") {
        return storeRoot.includes(category);
      }
      return false;
    },
    toggleCategory(category) {
      this.$store.commit("toggleDirectoryCategories", category);
    }
  },
  computed: {
    ...mapState(["categories", "isDirectoryFilterActive"])
  }
};
</script>
<style lang="scss">
#app {
  .v-input--switch {
    &__track {
      opacity: 1;
    }
    &__thumb {
      color: #fff !important;
    }
  }
}
.directory-categories {
  padding: 20px;
}

.parent-category__heading {
  margin: 0;
  font-size: 26px;
  font-weight: bold;
}
.directory-parent-category {
  &__label {
    font-size: 1.17em!important;
  }
  &__icon.v-icon {
    font-size: 2em;
  }
}
.filters-heading {
  font-size: 1.17em;
}
.map-grid-toggle.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
    opacity: 0;
}
</style>