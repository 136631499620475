<template>
  <div>
    <keep-alive>
      <div v-if="!selectedDuration">
        <v-container>
          <h3 class="text-left">How long are you in town for?</h3>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(option, index) in howLongOptions"
                :key="index"
                depressed
                text
                block
                @click="setHowLong(option)"
                class="text-left font-weight-normal"
              >
                <v-list-item-content>
                  <v-list-item-title class="itineraries__title" v-text="option.label"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-container>
      </div>
      <div v-else>
        <map-side-back-button @back="setHowLong" />
        <v-container>
          <h3 class="text-left">What are you interested in?</h3>
          <v-checkbox
            v-for="option in durationOptions"
            :key="option.id"
            :label="option.title"
            color="black"
            hide-details
            @change="updateCheckbox(option)"
            :input-value="isActive(option.itineraryId)"
          />
        </v-container>
      </div>
    </keep-alive>
  </div>
</template>
<script>
import MapSideBackButton from "./MapSideBackButton";
export default {
  components: {
    MapSideBackButton
  },
  data() {
    return {
      selectedDuration: null,
      howLongOptions: [
        {
          label: "A few hours",
          id: 1,
          selected: []
        },
        {
          label: "Overnight",
          id: 2
        },
        {
          label: "A few days",
          id: 3
        },
        {
          label: "A week",
          id: 4
        }
      ],
      interests: {
        selected: null
      }
    };
  },
  methods: {
    setHowLong(value) {
      if (value) return (this.selectedDuration = value);
      return (this.selectedDuration = null);
    },
    updateCheckbox(value) {
      this.$store.commit("updateItineraryOptions", value);
    },
    isActive(id) {
      if (this.$store.state.selected.itineraries)
        return this.$store.state.selected.itineraries.itineraryId === id;
      return false;
    }
  },
  computed: {
    durationOptions() {
      let duration = this.selectedDuration;
      return this.$store.state.itinerariesData.filter(itinerary => {
        if (itinerary.durations.nodes[0])
          return itinerary.durations.nodes[0].name == duration.label;
      });
    }
  }
};
</script>
<style lang="scss">
  .itineraries {
    &__title {
      font-size: 1em;
    }
  }
</style>