<template>
  <div>
    <v-container>
      <h2 class="parent-category__heading text-left" v-html="getDirectoryCategory[0].name"></h2>
    </v-container>
    <v-container class="pb-0">
    <div class="text-left filters-heading">Filters</div>
    </v-container>
    <v-list>
      <v-list-group color="black" v-if="subcategories.length > 0">
        <template v-slot:activator>
          <v-list-item-title
            ><h2 class="text-left ma-0">Subcategories</h2></v-list-item-title
          >
        </template>
        <v-container>
          <v-select
            v-model="subcategoriesModel"
            :items="subcategories"
            item-text="name"
            item-value="databaseId"
            label="Select one or more subcategories"
            hint="Select subcategories"
            multiple
            chips
            return-object
          >
          </v-select>
        </v-container>
      </v-list-group>
      <v-list-group color="black" v-if="regions.length > 0">
        <template v-slot:activator>
          <v-list-item-title
            ><h2 class="text-left ma-0">Regions</h2></v-list-item-title
          >
        </template>
        <v-container>
          <v-select
            v-model="regionsModel"
            :items="regions"
            item-text="name"
            item-value="databaseId"
            label="Select one or more regions"
            hint="Select regions"
            multiple
            chips
            return-object
          >
          </v-select>
        </v-container>
      </v-list-group>
      <v-list-group color="black" v-if="towns.length > 0">
        <template v-slot:activator>
          <v-list-item-title
            ><h2 class="text-left ma-0">Towns</h2></v-list-item-title
          >
        </template>
        <v-container>
          <v-select
            v-model="townsModel"
            :items="towns"
            item-text="name"
            item-value="databaseId"
            label="Select one or more towns"
            hint="Select towns"
            multiple
            chips
            return-object
          >
          </v-select>
        </v-container>
      </v-list-group>
      <v-list-group color="black" v-if="interests.length > 0">
        <template v-slot:activator>
          <v-list-item-title
            ><h2 class="text-left ma-0">Interests</h2></v-list-item-title
          >
        </template>
        <v-container>
          <v-select
            v-model="interestsModel"
            :items="interests"
            item-text="name"
            item-value="databaseId"
            label="Select one or more interests"
            hint="Select interests"
            multiple
            chips
            return-object
          >
          </v-select>
        </v-container>
      </v-list-group>
      <v-list-group color="black" v-if="facilities.length > 0">
        <template v-slot:activator>
          <v-list-item-title
            ><h2 class="text-left ma-0">Facilities</h2></v-list-item-title
          >
        </template>
        <v-container>
          <v-select
            v-model="facilitiesModel"
            :items="facilities"
            item-text="name"
            item-value="databaseId"
            label="Select one or more facilities"
            multiple
            chips
            return-object
          >
          </v-select>
        </v-container>
      </v-list-group>
    </v-list>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Directory",
  data() {
    return {
    }
  },
  methods: {
    toggleAccessibility() {
      this.$store.commit("updateAccessibility", {parentId: this.$store.state.activeDirectoryCategory});
    },
    toggleSubcategory(subcategoryId) {
      this.$store.commit("updateSubcategory", {subcategoryId, parentId: this.$store.state.activeDirectoryCategory});
    },
    toggleLocation(locationId) {
      this.$store.commit("updateLocation", locationId);
    },
    isActiveOption(option, value) {
      return this.$store.state.selected[option].includes(value)
    },
    isActiveDirectoryOption(option, value) {
      return this.$store.state.selected.directory.filter(option => {
        return option.parent === this.activeDirectoryCategory
      })[0][option] // e.g. facilities, subcategories
      .includes(value)
    },
  },
  computed: {
    // use for multiselect
    locationz: {
      get() {
        return this.$store.state.selected.directory.filter(directory => {
          return directory.parent === this.activeDirectoryCategory
        })[0].locations
      },
      set (value) {
        this.$store.commit('updateLocationz', value)
      }
    },
    facilitiesModel: {
      get() {
        return this.$store.state.selected.directory.filter(directory => {
          return directory.parent === this.activeDirectoryCategory
        })[0].facilities
      },
      set (facilities) {
        let payload = {
          facilities,
          parentId: this.activeDirectoryCategory
        }
        this.$store.commit("updateFacilities", payload);
      }
    },
    getDirectoryCategory() {
      return this.$store.state.categories.filter(category => {
        return category.databaseId === this.activeDirectoryCategory
      })
    },
    subcategories() {
        return this.$store.state.categories
        .filter(category => {
          return category.databaseId === this.activeDirectoryCategory
        })[0].children.nodes
    },
    subcategoriesModel: {
      get() {
        return this.$store.state.selected.directory.filter(directory => {
          return directory.parent === this.activeDirectoryCategory
        })[0].subcategories
      },
      set (subcategories) {
        let payload = {
          subcategories,
          parentId: this.activeDirectoryCategory
        }
        this.$store.commit("updateSubcategories", payload);
      }
    },
    regionsModel: {
      get() {
        return this.$store.state.selected.directory.filter(directory => {
          return directory.parent === this.activeDirectoryCategory
        })[0].regionsTerms
      },
      set (regions) {
        let payload = {
          regions,
          parentId: this.activeDirectoryCategory
        }
        this.$store.commit("updateRegions", payload);
      }
    },
    regions() {
      let foundRegions = []

      this.$store.state.operatorData.filter(operator => {
        return operator.operatorCategories.nodes.some(category => {
          return category.databaseId === this.$store.state.activeDirectoryCategory
        })
      })
      .forEach(operator => {
        let regions = operator.regionsTerms.nodes;
        if (regions) {
          regions.forEach(region => {
              foundRegions.push(region)
          })
        }
      });
      
      return foundRegions
      .filter((obj, pos, arr) => { // remove duplicates
        return arr.map(mapObj => mapObj['databaseId']).indexOf(obj['databaseId']) === pos;
      });
    },
    townsModel: {
      get() {
        return this.$store.state.selected.directory.filter(directory => {
          return directory.parent === this.activeDirectoryCategory
        })[0].townsTerms
      },
      set (towns) {
        let payload = {
          towns,
          parentId: this.activeDirectoryCategory
        }
        this.$store.commit("updateTowns", payload);
      }
    },
    towns() {
      let foundTowns = []
      
      this.$store.getters.filteredOperators
      .forEach(operator => {
        let towns = operator.townsTerms.nodes;
        if (towns) {
          towns.forEach(town => {
            foundTowns.push(town)
          })
        }
      });
      
      // this.$store.state.operatorData.filter(operator => {
      //   return operator.operatorCategories.nodes.some(category => {
      //     return category.databaseId === this.$store.state.activeDirectoryCategory
      //   })
      // })
      // .forEach(operator => {
      //   let towns = operator.townsTerms.nodes;
      //   if (towns) {
      //     towns.forEach(town => {
      //         foundTowns.push(town)
      //     })
      //   }
      // });
      
      return foundTowns
      .filter((obj, pos, arr) => { // remove duplicates
        return arr.map(mapObj => mapObj['databaseId']).indexOf(obj['databaseId']) === pos;
      });
    },
    interestsModel: {
      get() {
        return this.$store.state.selected.directory.filter(directory => {
          return directory.parent === this.activeDirectoryCategory
        })[0].interests
      },
      set (interests) {
        let payload = {
          interests,
          parentId: this.activeDirectoryCategory
        }
        this.$store.commit("updateInterests", payload);
      }
    },
    interests() {
      return this.$store.state.interests;
    },
    facilities() {
      let foundFacilities = []

      this.$store.state.operatorData.filter(operator => {
        return operator.operatorCategories.nodes.some(category => {
          return category.databaseId === this.$store.state.activeDirectoryCategory
        })
      })
      .forEach(operator => {
        let facilities = operator.facilities.nodes;
        if (facilities) {
          facilities.forEach(facility => {
              foundFacilities.push(facility)
          })
        }
      });
      
      return foundFacilities
      .filter((obj, pos, arr) => { // remove duplicates
        return arr.map(mapObj => mapObj['databaseId']).indexOf(obj['databaseId']) === pos;
      });
    },
    ...mapState(["activeDirectoryCategory"])
  }
};
</script>