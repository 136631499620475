<template>
  <v-list>
    <v-list-item-group>
      <v-list-item depressed text block @click="$emit('back')">
        <v-list-item-icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-left back-button">{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
export default {
  props: {
    text: {
      default: "Back to categories",
      type: String
    }
  }
};
</script>
<style lang="scss">
  .back-button {
    font-size: 18px!important;
  }
</style>