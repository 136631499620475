<template>
  <div class="operator-listing-slim">
    <v-container>
      <h2 class="map-operator-side__title" v-html="operator.title" />
      <div v-if="operatorCategories" class="map-operator-side__categories">
        <div
          class="map-operator-side__category"
          v-for="(category, i) in operatorCategories"
          :key="i"
        >
          {{ category }}
        </div>
      </div>
      <div class="text-center mt-2 mb-5">
        <v-btn
          block
          color="primary"
          class="map-operator-side__link font-weight-bold"
          depressed
          :href="baseUrl + '/operator/' + operator.slug"
          >View details</v-btn
        >
      </div>
      <div
        class="operator-button-group"
        v-if="operator.OperatorData.operatorMapMarker"
      >
        <add-to-trip-planner :id="operator.operatorId" />
        <get-directions
          :lat="operator.OperatorData.operatorMapMarker.latitude"
          :lng="operator.OperatorData.operatorMapMarker.longitude"
        />
      </div>
      <div class="distance-to-next" v-if="distanceActive">
        <span> Drive: {{ duration }} ({{ distance }}) </span>
      </div>
    </v-container>
  </div>
</template>
<script>
import AddToTripPlanner from "./AddToTripPlanner";
import GetDirections from "./GetDirections";
import { mapState } from "vuex";

export default {
  components: {
    AddToTripPlanner,
    GetDirections
  },
  props: {
    operator: Object,
    leg: Number
  },
  computed: {
    ...mapState(["baseUrl"]),
    operatorCategories() {
      if (
        this.operator.operatorData &&
        this.operator.operatorData.operatorType
      ) {
        if (
          this.operator.operatorData.operatorType[0] === "placesToStayGroup"
        ) {
          return this.operator.accommodationTypes.nodes.map(category => {
            return category.name;
          });
        }
        if (this.operator.operatorData.operatorType[0] === "eatAndDrinkGroup") {
          return this.operator.foodAndDrinkTypes.nodes.map(category => {
            return category.name;
          });
        }
      }
      return null;
    },
    duration() {
      if (
        this.$store.state.activeRoute &&
        this.leg < this.$store.state.activeRoute.routes[0].legs.length > 0
      ) {
        return this.$store.state.activeRoute.routes[0].legs[this.leg].duration
          .text; // need to check this
      }
      return false;
    },
    distance() {
      if (
        this.$store.state.activeRoute &&
        this.leg < this.$store.state.activeRoute.routes[0].legs.length > 0
      ) {
        return this.$store.state.activeRoute.routes[0].legs[this.leg].distance
          .text; // need to check this
      }
      return false;
    },
    distanceActive() {
      return (
        this.$store.state.activeRoute &&
        this.leg < this.$store.state.activeRoute.routes[0].legs.length
      );
    }
  }
};
</script>
<style lang="scss">
.operator-button-group {
  display: flex;
  justify-content: space-between;
  button,
  a {
    font-size: 12px;
  }
}
.map-operator-side__star-rating-label {
  line-height: 1.9em;
}
.distance-to-next {
  position: relative;
  height: 2em;
  line-height: 2em;
  text-align: center;
  z-index: 0;
  top: 1.3em;

  span {
    display: inline-block;
    padding: 0 0.7em;
    background: white;
    position: relative;
    z-index: 2;
  }

  &::after {
    content: "";
    display: block;
    background: rgb(230, 230, 230);
    height: 2px;
    width: 100%;
    position: absolute;
    top: calc(50% - 1px);
  }
}

.operator-listing-slim {
  width: 100%;
  .map-operator-side {
    &__title {
      height: initial;
    }
  }
}
</style>