import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#000",
        secondary: "#F5685C",
        anchor: "#fff",
        green: "#359B9E",
        red: "#F5685C",
        blue: "#24B4E9",
        purple: "#7C64B3",
        lightgrey: "#e5e5e5",
        lightergrey: "#f2f2f2",
        background: "#f2f2f2"
      }
    }
  }
});
