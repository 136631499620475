var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-sidebar"},[_c('div',{staticClass:"map-mobile-header-container"},[_c('map-side-header')],1),_c('div',[_c('div',{staticClass:"mobile-content-area",class:{ 'is-loading': _vm.isLoading }},[(_vm.activeView === 'map')?_c('GoogleMapLoader',{key:_vm.googleMapStateKey,attrs:{"mapConfig":_vm.mapConfig,"apiKey":"AIzaSyAYiVpOY2ewN4aatyLM1Z7_A2JrsyRUHTU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var google = ref.google;
var map = ref.map;
return [_vm._l((_vm.markers),function(marker){return _c('GoogleMapMarker',{key:marker.id,attrs:{"marker":marker,"google":google,"map":map}})}),_c('GoogleMapRoute',{key:_vm.googleMapRouteStateKey,attrs:{"route":_vm.route,"google":google,"map":map}})]}}],null,false,2345452788)}):_vm._e(),(_vm.activeView === 'grid')?_c('div',[(_vm.isMobile && _vm.tripPlannerActive)?_c('trip-planner-side',{staticClass:"filter-sidebar"}):_c('operator-grid')],1):_vm._e(),_c('map-operator-side-mobile')],1),_c('div',[(
          _vm.activeView === 'map' &&
            _vm.activeParentType === 'itineraries' &&
            !_vm.isMobile &&
            !_vm.tripPlannerActive &&
            _vm.itinerariesSidebar
        )?_c('map-itineraries-side',{staticClass:"filter-sidebar"}):_vm._e(),(_vm.activeView === 'map' && !_vm.isMobile && _vm.tripPlannerActive)?_c('trip-planner-side',{staticClass:"filter-sidebar"}):_vm._e(),(_vm.sidebarOpen)?_c('div',{staticClass:"filter-sidebar"},[_c('map-side-footer'),(_vm.sidebarOpen)?_c('div',{staticClass:"footer-sidebar-buttons"},[(_vm.isMobile)?_c('v-btn',{staticClass:"filter-button",attrs:{"color":"secondary","x-large":""},on:{"click":function($event){return _vm.toggleSidebar(false)}}},[_vm._v("Show results")]):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Reset filter?")]),_c('v-card-text',[_vm._v("\n              Are you sure you want to reset the filter and show all\n              operators?\n            ")]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("\n                Cancel\n              ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetData()}}},[_vm._v("\n                Reset filter\n              ")])],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }