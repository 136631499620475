<template>
  <v-btn class="add-to-trip-planner" text @click="tripPlannerToggle">
    <v-icon left :color="buttonData.iconColour">
      {{ buttonData.icon }}
    </v-icon>
    <span>{{ buttonData.text }}</span>
  </v-btn>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      tripPlanner: {
        active: {
          text: "Remove from trip board",
          icon: "mdi-heart",
          iconColour: "primary"
        },
        inactive: {
          text: "Add to trip board",
          icon: "mdi-heart-outline",
          iconColour: "primary"
        }
      }
    };
  },
  methods: {
    tripPlannerToggle() {
      this.$store.commit("tripPlannerToggle", this.id);
    }
  },
  computed: {
    buttonData() {
      if (this.inTripPlanner) {
        return this.tripPlanner.active;
      }
      return this.tripPlanner.inactive;
    },
    inTripPlanner() {
      return this.$store.state.tripPlannerData.includes(this.id);
    }
  }
};
</script>

<style lang="scss">
  .add-to-trip-planner {
    font-size: 14px;
  }
</style>