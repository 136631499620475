<template>
  <div class="trip-planner-side">
    <v-navigation-drawer :value="true" hide-overlay permanent absolute width="420">

      <div
        class="trip-planner-footer-buttons"
        v-if="tripPlannerData.length > 0"
      >
        <button
          class="btn button button--secondary"
          @click="updateShareModal(true)"
        >
          Share trip
        </button>
        <a :href="tripUrl" class="btn button start-trip" target="_blank"
          >Start Trip</a
        >
      </div>
      <div v-else class="pa-10">
        <h2 class="text-left">You haven’t added anything to your trip plan yet!</h2>
        <p>Browse our site and add places and operators you love by clicking the suitcase when you see this feature:</p>
        <div class="text-center mb-3">
          <v-btn class="add-to-trip-planner" text>
            <!-- <v-icon left color="primary">
              mdi-heart-outline
            </v-icon> -->
            <span>Add to My Trip Plan</span>
          </v-btn>
        </div>
        <p>Then return to this page and use your trip planner to create your perfect itinerary!</p>
      </div>
      <trip-planner-feed />
    </v-navigation-drawer>
    <favourites-share
      :modal-open="shareModalActive"
      :share-url="shareUrl"
      @updateModal="updateShareModal"
    ></favourites-share>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import TripPlannerFeed from "./TripPlannerFeed";
import FavouritesShare from "./FavouritesShare";

export default {
  data: function() {
    return {
      shareModalActive: false
    };
  },
  components: {
    TripPlannerFeed,
    FavouritesShare
  },
  computed: {
    ...mapGetters(["filteredOperators"]),
    ...mapState(["tripPlannerActive", "tripPlannerData"]),
    selectedItinerary() {
      let selectedItinerary = this.$store.state.selected.itineraries;
      if (selectedItinerary) return true;
      return false;
    },
    shareUrl() {
      // let queryString = "";
      // if (this.operatorIds !== "") {
      //   for (let i = 0; i < this.operatorIds.length; i++) {
      //     queryString += "&id[]=" + this.operatorIds[i];
      //   }
      //   return queryString;
      // }
      return "foo";
      // return false;
    },
    tripUrl() {
      let url = "https://www.google.com/maps/dir//";
      this.filteredOperators.forEach(operator => {
        url +=
          operator.OperatorData.operatorMapMarker.latitude +
          "," +
          operator.OperatorData.operatorMapMarker.longitude +
          "/";
      });
      return url;
    }
  },
  methods: {
    updateShareModal: function(state) {
      this.shareModalActive = state;
    }
  }
};
</script>

<style lang="scss">
.trip-planner-side {
  z-index: 4;
}
.trip-planner-footer-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
.start-trip {
  color: initial !important;
}
</style>