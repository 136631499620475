<template>
  <v-btn class="from-from-trip-planner" text @click="tripPlannerToggle">
    <v-icon left color="secondary">
      mdi-close
    </v-icon>
  </v-btn>
</template>
<script>
export default {
  props: ["id"],
  methods: {
    tripPlannerToggle() {
      this.$store.commit("tripPlannerToggle", this.id);
    }
  }
};
</script>