<template>
  <div class="map-sidebar-top">
    <v-container>
      <v-row align="center" justify="center" dense>
        <v-col
          v-if="
            (!tripPlannerActive) ||
              isMobile
          "
          cols="6"
        >
          <v-btn
            @click="setActiveView('grid')"
            block
            color="white"
            :class="{'inactive':(activeView !== 'grid')}"
            class="map-grid-toggle map-grid-toggle__grid"
          >
            <v-icon left>mdi-grid</v-icon>
            Grid
          </v-btn>
        </v-col>
        <v-col
          v-if="
            (!tripPlannerActive) ||
              isMobile
          "
          cols="6"
        >
            <!-- v-if="activeView === 'map'" -->
          <v-btn
            @click="setActiveView('map')"
            block
            color="white"
            :class="{'inactive':(activeView !== 'map')}"
            class="map-grid-toggle"
          >
            <v-icon left>mdi-map</v-icon>
            Map
          </v-btn>
        </v-col>
        <!-- <v-col v-if="!tripPlannerActive" cols="auto">
          <v-btn text color="white" @click="toggleSearch(true)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    activeView: {
      get() {
        return this.$store.state.activeView;
      },
      set() {
        return;
      }
    },
    ...mapState(["tripPlannerActive", "activeParentType"]),
    ...mapGetters(["isMobile"])
  },
  methods: {
    activeView(type) {
      return this.activeView === type
    },
    setActiveView(view) {
      this.$store.commit("activeView", view);
    },
    toggleTripPlanner() {
      this.$store.commit("tripPlannerActive");
    }
  }
};
</script>
<style lang="scss">
.map-sidebar-top {
  background: #a9a9a9;
}
.view-trip-planner {
  @media screen and (max-width: 480px) {
    font-size: 10px !important;
  }
}
.map-grid-toggle {
  &.inactive {
    color: gray;
    opacity: 0.5;
    font-size: 30px;
  }

  .v-btn__content {
    font-size: 1.17em;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px !important;
    .v-icon {
      font-size: 18px !important;
    }
  }
}
</style>