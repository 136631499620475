<script>
export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    route: {
      type: Array,
      required: true
    }
  },

  mounted() {
    this.setRoute();
  },
  methods: {
    setRoute() {
      let _this = this;
      let points = this.$store.getters.routes;
      if (points.length > 0) {
        let directionsService = new this.google.maps.DirectionsService();
        let directionsRenderer = new this.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "black",
            strokeOpacity: 0.4,
            strokeWeight: 6
          },
          preserveViewport: true
        });
        let points = this.$store.getters.routes;
        let origin = points[0].position;
        let destination = points[points.length - 1].position;
        let waypoints = points.filter((point, index) => {
          if (index !== 0 && index !== points.length - 1) {
            return true;
          }
          return false;
        });
        waypoints = waypoints.map(point => {
          return {
            location: point.position,
            stopover: true
          };
        });
        directionsRenderer.setMap(this.map);
        directionsService.route(
          {
            // origin: { query: this.route[0].position },
            origin: origin, //{ query: this.route[0].position },
            destination: destination,
            waypoints: waypoints,
            // optimizeWaypoints: true,
            // destination: { query: this.route[1].position },
            travelMode: "DRIVING"
          },
          function(response, status) {
            if (status === "OK") {
              directionsRenderer.setDirections(response);
              _this.$store.commit("activeRoute", response);
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      }
    }
  },
  render() {
    return true;
  }
};
</script>