// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import Vuex from "vuex";
import App from "./App";
import { state } from "./state";
// import {getters} from './getters'
import { mutations } from "./mutations";
import { actions } from "./actions";

import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.use(Vuex);

// const site_url = 'https://mudgee.wisdom.com.au/wp'
// const site_url = window.location.origin+'/wp'
const store = new Vuex.Store({
  state,
  getters: {
    selected: state => state.selected,
    isMobile() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      } else {
        return false;
      }
    },
    activeOperator: (state) => {
      if (state.activeMarker) {
        let activeOperator = state.operatorData.filter(
          (marker) => marker.operatorId === state.activeMarker
        );
        if (activeOperator.length > 0) return activeOperator[0];
      }
      return false;
    },
    operatorMarkers: (state) => {
      if (state.activeView === "grid") return [];
      let operatorData = store.getters.filteredOperators;
      if (operatorData && operatorData.length > 0) {
        let markers = [];
        operatorData.forEach((element) => {
          // let category = null;
          // if (element.operatorData.operatorType)
          // category = element.operatorData.operatorType;

          let parentCategory = state.categories.filter((category) => {
            return element.operatorCategories.nodes.some((operatorCategory) => {
              return operatorCategory.databaseId === category.databaseId;
            });
          });
          if (parentCategory && parentCategory.length > 0)
            parentCategory = parentCategory[0].slug;
          if (
            element.OperatorData.operatorMapMarker &&
            element.OperatorData.operatorMapMarker.latitude &&
            element.OperatorData.operatorMapMarker.longitude
          ) {
            let marker = {
              id: element.operatorId,
              category: parentCategory, // if an operator has more than one type, they may show the wrong colour ¯\_(ツ)_/¯
              title: element.title,
              position: {
                lat: element.OperatorData.operatorMapMarker.latitude,
                lng: element.OperatorData.operatorMapMarker.longitude,
              },
            };
            markers.push(marker);
            // bounds.extend(marker.position);
          }
        });
        // if (markers.length > 0) state.map.fitBounds(bounds);
        return markers;
      }
      return [];
    },
    routes: (state) => {
      if (
        state.tripPlannerActive === true &&
        state.tripPlannerData.length > 0
      ) {
        let operatorData = state.operatorData;
        let waypoints = [];
        operatorData.forEach((element) => {
          let tripPlannerDataIndex = state.tripPlannerData.indexOf(
            element.operatorId
          );
          if (tripPlannerDataIndex !== -1) {
            if (
              element.OperatorData.operatorMapMarker &&
              element.OperatorData.operatorMapMarker.latitude &&
              element.OperatorData.operatorMapMarker.longitude
            ) {
              waypoints[tripPlannerDataIndex] = {
                id: element.operatorId,
                position: {
                  lat: element.OperatorData.operatorMapMarker.latitude,
                  lng: element.OperatorData.operatorMapMarker.longitude,
                },
              };
            }
          }
        });
        state.googleMapRouteStateKey++;
        return waypoints;
      }
      if (state.activeParentType !== "itineraries") return [];
      let itineraryOperatorIds = store.getters.itineraryOperatorIds;
      let operatorData = state.operatorData;
      if (itineraryOperatorIds.length > 0) {
        let waypoints = [];
        operatorData.forEach((element) => {
          let itineraryOperatorIdsIndex = itineraryOperatorIds.indexOf(
            element.operatorId
          );
          if (itineraryOperatorIdsIndex !== -1) {
            if (
              element.OperatorData.operatorMapMarker &&
              element.OperatorData.operatorMapMarker.latitude &&
              element.OperatorData.operatorMapMarker.longitude
            ) {
              waypoints[itineraryOperatorIdsIndex] = {
                id: element.operatorId,
                position: {
                  lat: element.OperatorData.operatorMapMarker.latitude,
                  lng: element.OperatorData.operatorMapMarker.longitude,
                },
              };
            }
          }
        });
        return waypoints;
      }
      return [];
    },
    trailOperatorIds: (state) => {
      let trailsOperatorIds = [];
      let trails = state.selected.trails;
      let trailsData = state.trailsData;
      for (let i = 0; i < trails.length; i++) {
        let trailOperators = trailsData.filter((trail) => {
          return trail.trailId === trails[i];
        });
        if (trailOperators.length > 0) {
          trailOperators = trailOperators[0].acfOperators.operators;
          for (let x = 0; x < trailOperators.length; x++) {
            if (trailOperators[x].operator.operatorId) {
              trailsOperatorIds.push(trailOperators[x].operator.operatorId);
            }
          }
        }
      }
      return trailsOperatorIds;
    },
    itineraryOperatorIds: (state) => {
      let itinerariesOperatorIds = [];
      let itineraries = state.selected.itineraries;
      if (itineraries && itineraries.acfOperators) {
        let itineraryOperators = itineraries.acfOperators.operators;
        for (let x = 0; x < itineraryOperators.length; x++) {
          if (
            itineraryOperators[x].operator &&
            itineraryOperators[x].operator.operatorId
          ) {
            itinerariesOperatorIds.push(
              itineraryOperators[x].operator.operatorId
            );
          }
        }
        return itinerariesOperatorIds;
      }
      return [];
    },
    getTripPlannerOperators: (state) => {
      let operators = [];
      state.operatorData.forEach((operator) => {
        let operatorIndex = state.tripPlannerData.findIndex((x) => {
          return operator.operatorId === x;
        });
        if (operatorIndex !== -1) {
          operators[operatorIndex] = operator;
        }
      });
      return operators;
    },
    getTrailsOperators: (state) => {
      let trailOperatorIds = store.getters.trailOperatorIds;
      let operatorData = state.operatorData;
      if (trailOperatorIds.length > 0) {
        let operators = [];
        operatorData.forEach((operator) => {
          let trailOperatorIdsIndex = trailOperatorIds.indexOf(
            operator.operatorId
          );
          if (trailOperatorIdsIndex !== -1) {
            operators[trailOperatorIdsIndex] = operator;
          }
        });
        return operators;
      }
    },
    getItinerariesOperators: (state) => {
      state.activeView = "map";

      let itineraryOperatorIds = store.getters.itineraryOperatorIds;
      let operatorData = state.operatorData;
      if (itineraryOperatorIds.length > 0) {
        let operators = [];
        operatorData.forEach((operator) => {
          let itineraryOperatorIdsIndex = itineraryOperatorIds.indexOf(
            operator.operatorId
          );
          if (itineraryOperatorIdsIndex !== -1) {
            operators[itineraryOperatorIdsIndex] = operator;
          }
        });
        return operators;
      }
    },
    filteredOperators: (state) => {
      if (state.tripPlannerActive) {
        return store.getters.getTripPlannerOperators;
      }

      if (state.activeParentType === "trails") {
        return store.getters.getTrailsOperators;
      }

      if (state.activeParentType === "itineraries") {
        return store.getters.getItinerariesOperators;
      }

      return state.operatorData
        .filter((operator) => {
          // check if operator matches parent category i.e things to do
          if (state.activeCategories.length > 0) {
            return state.activeCategories.some((category) => {
              return operator.operatorCategories.nodes.some(
                (operatorCategory) => {
                  return operatorCategory.databaseId === category;
                }
              );
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter accessibility
          if (state.selected.accessibility.length > 0) {
            return state.activeCategories.some((category) => {
              if (state.selected.accessibility.includes(category)) {
                // if parent category has accessibility option selected, check that the operator is accessible
                return operator.accessibles.nodes.some((access) => {
                  return (
                    access.slug === "disassist" || access.slug === "distassist"
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some(
                  (operatorCategory) => {
                    // if parent category has doesn't have accessible option selected, check that the operator meets this criteria (i.e. operator parent category matches)
                    return operatorCategory.databaseId === category;
                  }
                );
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter subcategories
          let selected_subcategories = state.selected.directory.some(
            (category) => {
              // if there are any subcategories selected
              return category.subcategories.length > 0;
            }
          );
          if (selected_subcategories) {
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].subcategories;

              if (children && children.length > 0) {
                return children.some((subcategory) => {
                  return operator.operatorCategories.nodes.some(
                    (operator_categories) => {
                      return operator_categories.databaseId === subcategory.databaseId;
                    }
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some(
                  (operator_categories) => {
                    return operator_categories.databaseId === category;
                  }
                );
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter facilities
          let selected_facilities = state.selected.directory.some(
            (category) => {
              // if there are any subcategories selected
              return category.facilities.length > 0;
            }
          );
          if (selected_facilities) {
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].facilities;

              if (children && children.length > 0) {
                return children.some((facility) => {
                  return operator.facilities.nodes.some(
                    (operator_facilities) => {
                      return (
                        operator_facilities.databaseId === facility.databaseId
                      );
                    }
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some((operatorCategory) => {
                  return operatorCategory.databaseId === category
                })
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter custom facilities
          let selected_facilities = state.selected.directory.some(
            (category) => {
              // if there are any subcategories selected
              return category.customFacilities.length > 0;
            }
          );
          if (selected_facilities) {
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].customFacilities;

              if (children && children.length > 0) {
                return children.some((facility) => {
                  return operator.customFacilities.nodes.some(
                    (operator_facilities) => {
                      return (
                        operator_facilities.databaseId === facility.databaseId
                      );
                    }
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some((operatorCategory) => {
                  return operatorCategory.databaseId === category
                })
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          console.warn(operator)
          // filter regions
          let selected_regions = state.selected.directory.some(
            (category) => {
              // if there are any subcategories selected
              return category.regionsTerms.length > 0;
            }
          );
          if (selected_regions) {
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].regionsTerms;

              if (children && children.length > 0) {
                return children.some((region) => {
                  return operator.regionsTerms.nodes.some(
                    (operator_regions) => {
                      return (
                        operator_regions.databaseId === region.databaseId
                      );
                    }
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some((operatorCategory) => {
                  return operatorCategory.databaseId === category
                })
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter towns
          let selected_towns = state.selected.directory.some(
            (category) => {
              // if there are any subcategories selected
              return category.townsTerms.length > 0;
            }
          );
          if (selected_towns) {
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].townsTerms;

              if (children && children.length > 0) {
                return children.some((town) => {
                  return operator.townsTerms.nodes.some(
                    (operator_towns) => {
                      return (
                        operator_towns.databaseId === town.databaseId
                      );
                    }
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some((operatorCategory) => {
                  return operatorCategory.databaseId === category
                })
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter towns
          let selected_interests = state.selected.directory.some(
            (category) => {
              // if there are any subcategories selected
              return category.interests.length > 0;
            }
          );
          if (selected_interests) {
            console.log('theres select interests')
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].interests;

              if (children && children.length > 0) {
                return children.some((interest) => {
                  return operator.interestTerms.nodes.some(
                    (operator_interests) => {
                      return (
                        operator_interests.databaseId === interest.databaseId
                      );
                    }
                  );
                });
              } else {
                return operator.operatorCategories.nodes.some((operatorCategory) => {
                  return operatorCategory.databaseId === category
                })
              }
            });
          }
          return true;
        })
        .filter((operator) => {
          // filter locations
          let selected_locations = state.selected.directory.some((category) => {
            // if there are any subcategories selected
            return category.locations.length > 0;
          });
          if (selected_locations) {
            return state.activeCategories.some((category) => {
              let children = state.selected.directory.filter((cat) => {
                return cat.parent === category;
              })[0].locations;

              if (children && children.length > 0) {
                return children.some((location) => {
                  return operator.locations.nodes.some((operator_locations) => {
                    return (
                      operator_locations.databaseId === location.databaseId
                    );
                  });
                });
              } else {
                return true;
              }
            });
          }
          return true;
        });
    },
  },
  mutations,
  actions,
});

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  vuetify,
  store,
}).$mount("#atdw-app");
