import Vue from "vue";
import axios from "axios";
export const mutations = {
        updateTripPlannerListingPosition(state, action) {
          let index = state.tripPlannerData.findIndex(x => {
            return x == action.id;
          });
          let arr = state.tripPlannerData;
          var f = arr.splice(index, 1)[0];
          if (action.direction === "up") index--;
          if (action.direction === "down") index++;
          arr.splice(index, 0, f);
          state.tripPlannerData = arr;
          state.googleMapStateKey++;
        },
        setitinerariesSidebar(state, action) {
          state.itinerariesSidebar = action;
        },
        setBaseUrl(state) {
          let site_url = window.location.origin
          let local = false
          if(site_url.includes('localhost')) {
            local = true
            // site_url = 'https://www.visitmudgeeregion.com.au'
            site_url = 'https://smt.w.test'
          }
          state.local = local
          state.baseUrl = site_url;
        },
        setCacheKeys(state) {
          let cache_key = null
          if(window.state && window.state.operator_cache_key) {
            cache_key = window.state.operator_cache_key
          }
          state.sessionStorageKey = cache_key
        },
        updateAccessible(state, accessible) {
          state.accessible = accessible;
        },
        updateCategories(state, categories) {
          state.categories = categories;
          categories.forEach(category => {
            state.selected.directory.push({
              parent: category.databaseId,
              subcategories: [],
              facilities: [],
              customFacilities: [],
              locations: [],
              regionsTerms: [],
              townsTerms: [],
              interests: [],
            })
          })
        },
        updateLocations(state, locations) {
          state.locations = locations;
        },
        activeParentType(state, type) {
          state.activeParentType = type;
          state.googleMapStateKey++;
        },
        activeRoute(state, route) {
          state.activeRoute = route;
          state.itinerariesRefreshKey++;
        },
        tripPlannerActive(state, value = null) {
          if (value) {
            state.tripPlannerActive = value;
          } else state.tripPlannerActive = !state.tripPlannerActive;
          if (state.tripPlannerActive === true) {
            state.activeView = "map";
          }
          state.googleMapStateKey++; // repaint Google map after filtered data
          state.itinerariesRefreshKey++;
        },
        tripPlannerToggle(state, operatorId) {
          let array = state.tripPlannerData;
          let i = array.indexOf(operatorId);
          if (i === -1) array.push(operatorId);
          else {
            array.splice(i, 1);
            state.googleMapStateKey++;
          }
          localStorage.setItem(
            "favourites_ids",
            JSON.stringify(state.tripPlannerData)
          );
        },
        activeDirectoryCategory(state, category) {
          state.activeDirectoryCategory = category;
        },
        isDirectoryFilterActive(state, payload) {
          state.isDirectoryFilterActive = payload;
        },
        clearData(state) {
          state.selected.trails = [];
          state.selected.itineraries = [];
          state.selected.accessibility = [];
          // state.selected.directory = {};
        //   Vue.set(state.selected, "directory", {});
          state.selected.directory = {};
          state.isDirectoryFilterActive = false;
        },
        googleMapMarkerCluster(state, cluster) {
          state.googleMapMarkerCluster = cluster;
        },
        incrementGoogleMapStateKey(state) {
          state.googleMapStateKey++;
        },
        updateMarker(state, marker) {
          state.activeMarker = null;
          if (state.activeMarker !== marker) return (state.activeMarker = marker);
          return (state.activeMarker = null);
        },
        toggleSidebar(state, set) {
          if (this.getters.isMobile === false) {
            state.googleMapStateKey++;
            // return (state.sidebarOpen = true);
          }
          if (set && typeof set === "boolean") {
            // clicking the toggle button parses MouseEvent as the set parameter, we only want true/false
            return (state.sidebarOpen = set);
          } else {
            state.sidebarOpen = !state.sidebarOpen;
          }
          if (state.sidebarOpen === false) state.googleMapStateKey++; // repaint Google map after filtered data
          return state.sidebarOpen;
        },
        activeView(state, view) {
          if (this.getters.isMobile === true) state.sidebarOpen = false;
          state.activeMarker = false;
          state.activeView = view;
          state.googleMapStateKey++;
        },
        updateTripPlannerData(state, response) {
          // state.tripPlannerOperators = state.tripPlannerOperators.concat(response);
          state.operatorData = state.operatorData.concat(response);
          // state.googleMapStateKey++; // repaint Google map after filtered data
        },
        updateOperatorData(state, response) {
          state.operatorData = state.operatorData.concat(response);
          // state.googleMapStateKey++; // repaint Google map after filtered data
        },
        updateItinerariesData(state, response) {
          state.itinerariesData = response;
        },
        updateItineraryOptions(state, response) {
          if (state.selected.itineraries === response) {
            state.selected.itineraries = null;
            state.itinerariesSidebar = false;
          } else {
            state.selected.itineraries = response;
            state.itinerariesSidebar = true;
          }
          state.googleMapStateKey++;
          /*
          let array = state.selected.itineraries;
          let i = array.indexOf(response);
          if (i === -1) array.push(response);
          else array.splice(i, 1);
          */
        },
        updateTrailsData(state, response) {
          state.trailsData = state.trailsData.concat(response);
        },
        updateTrailOptions(state, response) {
          let array = state.selected.trails;
          let i = array.indexOf(response);
          if (i === -1) array.push(response);
          else array.splice(i, 1);
          state.googleMapStateKey++;
        },
        updateAccessibility(state, payload) {
          if(state.selected.accessibility.includes(payload.parentId)) {
            state.selected.accessibility.splice(state.selected.accessibility.indexOf(payload.parentId), 1);
          } else {
            state.selected.accessibility.push(payload.parentId)
          }
        },
        updatePetFriendly(state) {
          if(state.selected.petFriendly.includes(state.activeDirectoryCategory)) {
            state.selected.petFriendly.splice(state.selected.petFriendly.indexOf(state.activeDirectoryCategory), 1);
          } else {
            state.selected.petFriendly.push(state.activeDirectoryCategory)
          }
        },
        updateSubcategories(state, payload) {
          state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0].subcategories = payload.subcategories
        },
        updateSubcategoryState(state, payload) {
          console.warn(payload)
          let category = state.selected.directory.filter(category => {
            return category.parent === payload.parent.databaseId
          })[0]
          if(category) {
            category.subcategories.push(payload)
          }
        },
        updateSubcategory(state, payload) {
          let category = state.selected.directory.filter(category => {
            return category.parent === payload.parentId
          })[0]
    
          if(category.subcategories.includes(payload.subcategoryId)) {
           category.subcategories.splice(category.subcategories.indexOf(payload.subcategoryId), 1);
          } else {
              category.subcategories.push(payload.subcategoryId)
          }
        },
        updateFacilities(state, payload) {
          state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0].facilities = payload.facilities
          state.googleMapStateKey++;
        },
        updateRegions(state, payload) {
          state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0].regionsTerms = payload.regions
          state.googleMapStateKey++;
        },
        updateTowns(state, payload) {
          state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0].townsTerms = payload.towns
          state.googleMapStateKey++;
        },
        setInterests(state, payload) {
          state.interests = payload
        },
        updateInterests(state, payload) {
          state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0].interests = payload.interests
          state.googleMapStateKey++;
        },
        updateFacility(state, payload) {
          let directory = state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0]
    
          if(directory.facilities.length > 0) {
            let facilityExists = directory.facilities.filter(facility => {
              return facility.databaseId === payload.facility.databaseId
            })
            if(facilityExists) {
              directory.facilities = directory.facilities.filter(facility => {
                return facility.databaseId !== payload.facility.databaseId
              })
            } else {
              directory.facilities.push(payload.facility)
            }
          } else {
            directory.facilities.push(payload.facility)
          }
        },
        updateCustomFacility(state, payload) {
          let directory = state.selected.directory.filter(directory => {
            return directory.parent === payload.parentId
          })[0]
    
          if(directory.customFacilities.length > 0) {
            let facilityExists = directory.customFacilities.filter(facility => {
              return facility.databaseId === payload.facility.databaseId
            })
            if(facilityExists) {
              directory.customFacilities = directory.customFacilities.filter(facility => {
                return facility.databaseId !== payload.facility.databaseId
              })
            } else {
              directory.customFacilities.push(payload.facility)
            }
          } else {
            directory.customFacilities.push(payload.facility)
          }
        },
        updateLocation(state, locationId) {
          let directory = state.selected.directory.filter(directory => {
            return directory.parent === state.activeDirectoryCategory
          })[0]
    
          if(directory.locations.includes(locationId)) {
           directory.locations.splice(directory.locations.indexOf(locationId), 1);
          } else {
              directory.locations.push(locationId)
          }
        },
        updateLocationz(state, location) {
          state.selected.directory.filter(directory => {
            return directory.parent === state.activeDirectoryCategory
          })[0].locations = location
        },
        setDirectoryCategories(state, payload) {
          let storeRoot = state.activeCategories;
          let i = storeRoot.indexOf(payload);
          if (i === -1) storeRoot.push(payload);
          if (storeRoot.length < 1) {
            Vue.delete(state.activeCategories);
          }
          state.googleMapStateKey++;
        },
        toggleDirectoryCategories(state, payload) {
          let storeRoot = state.activeCategories;
          let i = storeRoot.indexOf(payload);
          if (i === -1) storeRoot.push(payload);
          else storeRoot.splice(i, 1);
          if (storeRoot.length < 1) {
            Vue.delete(state.activeCategories);
          }
          state.googleMapStateKey++;
        },
        toggleRegion(state, payload) {
          state.selected.directory.forEach((category) => {
            category.regionsTerms.push(payload)
          })
        },
        getTripPlannerData(state, payload) {
          let id = "";
          if (payload) {
            id = payload
          }
          axios({
            url: `${state.baseUrl}/wp/graphql`,
            method: "post",
            data: {
              query: `
              query getTripPlannerData($id: Int) {
                operators(first: 1, where: {id: $id}) {
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  nodes {
                    id
                    operatorId
                    title
                    slug
                    OperatorData {
                      operatorMapMarker {
                        latitude
                        longitude
                        streetAddress
                      }
                      operatorImages {
                        mediaItemId
                        sourceUrl(size: TILE_THUMBNAIL)
                      }
                    }
                    operatorCategories {
                      nodes {
                        databaseId
                      }
                    }
                    facilities {
                      nodes {
                        name
                        slug
                        databaseId
                      }
                    }
                  }
                }
              }
              `,
              variables: {
                id: id
              }
            }
          }).then(response => {
    
            let operators = response.data.data.operators.nodes;
            state.operatorData = state.operatorData.concat(operators);
            // commit("updateTripPlannerData", operators);
            // state.googleMapStateKey++;
            // if (response.data.data.memberTypes.nodes[0].operators.pageInfo.hasNextPage === true) {
            //   dispatch("getOperatorData", {
            //     after: response.data.data.memberTypes.nodes[0].operators.pageInfo.endCursor
            //   });
            // } else {
            //   state.isLoading = false;
            //   state.googleMapStateKey++;
            // }
          });
        },
}