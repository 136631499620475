<template>
  <div>
    <div class="text-center ma-4" v-if="operators.length < 1 && !isLoading">
      <h2>No results found</h2>
      <div v-if="selectedSubcategories.length > 0">
        <p class="mb-0">Please simplify your search query</p>
      </div>
    </div>
    <div v-if="alphaPagination.length > 1 &&
            activeParentType !== 'itineraries' &&
            activeParentType !== 'trails' &&
            tripPlannerActive !== true">
      <div
        class="pagination"
        v-if="
          (alphaPagination.length > 1 || selectedPaginationItem) &&
            !isLoading
        "
      >
        <v-btn
          @click="updatePaginationItem()"
          small
          text
          outlined
          :class="['pagination-item',
          { active: selectedPaginationItem == '' }
          ]"
        >
          Show All
        </v-btn>
        <v-btn
          v-for="(item, index) in alphaPagination"
          small
          text
          @click="updatePaginationItem(item)"
          :key="index"
          :class="[
            'pagination-item',
            { active: item.label == selectedPaginationItem }
          ]"
        >
          {{ item.label }}
        </v-btn>
      </div>
      <div v-else>
          <v-skeleton-loader
            type="sentences"
            class="text-center ma-2"
          ></v-skeleton-loader>
      </div>
    </div>
    <div 
      class="text-center"
      v-if="activeParentType === 'directory'"
    >
      <v-chip 
        class="ma-1 mt-4"
        close
        v-for="(option, key) in selectedSubcategories" 
        :key="'selected-subcategory-'+key"
        dark
        :color="option.parent.categoryColour.colour"
        @click:close="removeSubcategory(option.data.databaseId, option.parent.databaseId)">
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedAccessible" 
        :key="'selected-accessible-'+key"
        @click:close="removeAccessible(option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedLocations" 
        :key="'selected-location-'+key"
        @click:close="removeLocation(option.data.databaseId, option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedRegions" 
        :key="'selected-regions-'+key"
        @click:close="removeRegion(option.data, option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedTowns" 
        :key="'selected-towns-'+key"
        @click:close="removeTown(option.data, option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedInterests" 
        :key="'selected-interest-'+key"
        @click:close="removeInterest(option.data, option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedFacilities" 
        :key="'selected-facilities-'+key"
        @click:close="removeFacility(option.data, option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
      <v-chip 
        class="ma-1 mt-4"
        close 
        v-for="(option, key) in selectedCustomFacilities" 
        :key="'selected-custom-facilities-'+key"
        @click:close="removeCustomFacility(option.data, option.parent.databaseId)"
        :color="option.parent.categoryColour.colour"
        dark>
        {{ option.data.name }}
      </v-chip>
    </div>
    <div
      class="operator-grid"
      :class="{ itineraries: itineraries }"
      v-if="activeParentType === 'itineraries' && selectedItinerary && operators"
    >
      <operator-listing-slim
        v-for="(operator, index) in operators"
        :key="'grid-slim-' + operator.id + itinerariesRefreshKey"
        :operator="operator"
        :leg="index"
        class="operator-grid-operator-slim"
      />
    </div>
    <div v-else>

      <div class="operator-grid" v-if="isLoading">
        <v-skeleton-loader
          v-for="index in 6"
          :key="index"
          type="image, article"
          class="operator-grid-operator"
        ></v-skeleton-loader>
      </div>
      <div class="operator-grid" v-else>
        <operator-listing
          v-for="operator in operators"
          :key="'grid-' + operator.id"
          :operator="operator"
          class="operator-grid-operator"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OperatorListing from "./OperatorListing";
import OperatorListingSlim from "./OperatorListingSlim";
import { mapState } from "vuex";
import pagination from "../constants/alphaPagination.js";

export default {
  data() {
    return {
      selectedPaginationItem: "",
      paginationItems: "operatorsAll",
      skeletonLoaderCount: 6
    };
  },
  props: {
    itineraries: Boolean
  },
  mixins: [pagination],
  components: {
    OperatorListing,
    OperatorListingSlim
  },
  computed: {
    ...mapState(["activeParentType", "currentPage", "isLoading"]),
    selectedSubcategories() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.subcategories.length > 0) {
          option.subcategories.forEach(subcategoryId => {
            this.$store.state.categories.forEach(category => {
              if(!this.$store.state.activeCategories.includes(category.databaseId)) return

                category.children.nodes.forEach(subcategory => {
                  if(subcategory.databaseId === subcategoryId.databaseId) {
                    selected.push(
                      {
                      data: subcategory, 
                      parent: this.$store.state.categories.filter(category => {
                        return category.databaseId === option.parent
                      })[0]
                    }
                  )
                }
              })
            })
          })
        }
      });
      return selected
    },
    selectedAccessible() {
      let selected = [];
      this.$store.state.selected.accessibility.forEach(categoryId => {
        selected.push({
          data: {
            name: 'Accessible'
          },
          parent: this.$store.state.categories.filter(category => {
            return category.databaseId === categoryId
          })[0]
        })
      });
      return selected

    },
    // selectedLocations() {
    //   let selected = [];
    //   this.$store.state.selected.directory.forEach(categoryId => {
    //     selected.push({
    //       data: {
    //         name: 'Accessible'
    //       },
    //       parent: this.$store.state.categories.filter(category => {
    //         return category.databaseId === categoryId
    //       })[0]
    //     })
    //   });
    //   return selected

    // },
    selectedRegions() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.regionsTerms.length > 0) {
          option.regionsTerms.forEach(region => {
              selected.push({
                data: region,
                parent: this.$store.state.categories.filter(category => {
                  return category.databaseId === option.parent
                })[0]
              })
          })
        }
      });
      return selected
    },
    selectedTowns() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.townsTerms.length > 0) {
          option.townsTerms.forEach(region => {
              selected.push({
                data: region,
                parent: this.$store.state.categories.filter(category => {
                  return category.databaseId === option.parent
                })[0]
              })
          })
        }
      });
      return selected
    },
    selectedInterests() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.interests.length > 0) {
          option.interests.forEach(interest => {
              selected.push({
                data: interest,
                parent: this.$store.state.categories.filter(category => {
                  return category.databaseId === option.parent
                })[0]
              })
          })
        }
      });
      return selected
    },
    selectedFacilities() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.facilities.length > 0) {
          option.facilities.forEach(facility => {
              selected.push({
                data: facility,
                parent: this.$store.state.categories.filter(category => {
                  return category.databaseId === option.parent
                })[0]
              })
          })
        }
      });
      return selected
    },
    selectedCustomFacilities() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.customFacilities.length > 0) {
          option.customFacilities.forEach(facility => {
              selected.push({
                data: facility,
                parent: this.$store.state.categories.filter(category => {
                  return category.databaseId === option.parent
                })[0]
              })
          })
        }
      });
      return selected
    },
    selectedLocations() {
      let selected = [];
      this.$store.state.selected.directory.forEach(option => {
        if(option.locations.length > 0) {
          option.locations.forEach(facility => {
              selected.push({
                data: facility,
                parent: this.$store.state.categories.filter(category => {
                  return category.databaseId === option.parent
                })[0]
              })
          })
        }
      });
      return selected
    },
    operatorsAll() {
      return this.$store.getters.filteredOperators;
    },
    operators() {
      let operators = null;

      if (this.$store.getters.filteredOperators) {
        operators = this.$store.getters.filteredOperators;
      } else {
        operators = this.$store.state.operatorData;
      }

      if (!this.tripPlannerActive && this.activeParentType !== "itineraries") {
        return operators
          .filter(item => {
            /* filter by pagination character */

            if (!this.selectedPaginationItem) return true;

            if (this.selectedPaginationItem == item.paginationKey) return true;
          })
          .sort((a, b) => {
            if (a.title.toLowerCase() == b.title.toLowerCase()) return 0;
            else return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
          });
      }
      return operators;
    },
    selectedItinerary() {
      let selectedItinerary = this.$store.state.selected.itineraries;
      if (selectedItinerary) return true;
      return false;
    },
    ...mapState(["tripPlannerActive", "itinerariesRefreshKey"])
  },
  methods: {
    removeSubcategory(subcategoryDatabaseId, parentId) {
      let subcategories = this.$store.state.selected.directory.filter(directory => {
        return directory.parent === parentId
      })[0].subcategories.filter(subcategory => {
        return subcategory.databaseId !== subcategoryDatabaseId
      })
      this.$store.commit("updateSubcategories", {parentId: parentId, subcategories});
    },
    removeAccessible(parentId) {
      let payload = {
        parentId
      }
      this.$store.commit("updateAccessibility", payload);
    },
    removeRegion(regionDatabaseId, parentId) {
      let regions = this.$store.state.selected.directory.filter(directory => {
        return directory.parent === parentId
      })[0].regionsTerms.filter(region => {
        return region.databaseId !== regionDatabaseId.databaseId
      })

      let payload = {
        regions,
        parentId
      }
      this.$store.commit("updateRegions", payload);
    },
    removeTown(townDatabaseId, parentId) {
      let towns = this.$store.state.selected.directory.filter(directory => {
        return directory.parent === parentId
      })[0].townsTerms.filter(region => {
        return region.databaseId !== townDatabaseId.databaseId
      })

      let payload = {
        towns,
        parentId
      }
      this.$store.commit("updateTowns", payload);
    },
    removeInterest(interestDatabaseId, parentId) {
      let interests = this.$store.state.selected.directory.filter(directory => {
        return directory.parent === parentId
      })[0].interests.filter(interest => {
        return interest.databaseId !== interestDatabaseId.databaseId
      })

      let payload = {
        interests,
        parentId
      }
      this.$store.commit("updateInterests", payload);
    },
    removeFacility(facilityDatabaseId, parentId) {
      let facilities = this.$store.state.selected.directory.filter(directory => {
        return directory.parent === parentId
      })[0].facilities.filter(facility => {
        return facility.databaseId !== facilityDatabaseId.databaseId
      })
      // [0]
      // .facilities.filter(facility => {
      //   return facility.databaseId !== facilityDatabaseId
      // })
        console.log(facilities)
      let payload = {
        facilities,
        parentId
      }
      this.$store.commit("updateFacilities", payload);
    },
    removeCustomFacility(facility, parentId) {
      let payload = {
        facility,
        parentId
      }
      this.$store.commit("updateCustomFacility", payload);
    },
    removeLocation(locationDatabaseId, parentId) {
      let locations = this.$store.state.selected.directory.filter(directory => {
        return directory.parent === parentId
      })[0].locations.filter(location => {
        return location.databaseId !== locationDatabaseId
      })

      this.$store.commit("updateLocationz", locations)
    },
    toggleOption(option, data, type, parentType) {
      let payload = {
        id: parentType,
        option: option,
        data: data,
        type: type
      };
      this.$store.commit("updateDirectoryOptions", payload);
      return true;
    },
    updatePaginationItem(item = false) {
      if (item === false) {
        this.selectedPaginationItem = false;
      }
      if (this.selectedPaginationItem == item.label) {
        this.selectedPaginationItem = false; //toggle off if clicked on again
        this.pagesTotal = Math.ceil(this.operators.length / this.itemsPerPage);
      } else {
        this.selectedPaginationItem = item.label;
        this.pagesTotal = Math.ceil(item.count / this.itemsPerPage);
      }

      this.pageNumber = 1;
    },
    resetData() {
      return this.$store.commit("clearData");
    }
  }
};
</script>
<style lang="scss">
.operator-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 100em;
  margin: 0 auto 4em auto;
  justify-content: center;

  @media screen and (min-width: 768px) {
    &.itineraries {
      margin-top: 60px;
      margin-left: auto;
      position: relative;
      z-index: 9;
    }
  }

  &-operator {
    width: 100%;
    max-width: 24em;
    margin: 1em;
  }
}

.operator-grid-operator {
  background: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.pagination {
    margin: 1em 0.5em 0.5em 0.5em;
    text-align: center;
}

.pagination-item {
    // @extend .button;
    // @extend .button--slim;

    margin-left: 0.2em;
    margin-right: 0.2em;
    background: transparent;
    // color: $brand-body-text;

    &.active {
        // @extend .button;
        // @extend .button--secondary;
        // @extend .button--slim;
    }
}
</style>