<template>
  <div class="map-sidebar">
    <div class="map-mobile-header-container">
      <map-side-header />
    </div>
    <div>
      <div :class="{ 'is-loading': isLoading }" class="mobile-content-area">
        <GoogleMapLoader
          v-if="activeView === 'map'"
          :mapConfig="mapConfig"
          :key="googleMapStateKey"
          apiKey="AIzaSyAYiVpOY2ewN4aatyLM1Z7_A2JrsyRUHTU"
        >
          <!-- apiKey="AIzaSyB3Ok2BdsLzeOP6sGpnXxWWnrQU4Yd9zFs" -->
          <template slot-scope="{ google, map }">
            <GoogleMapMarker
              v-for="marker in markers"
              :key="marker.id"
              :marker="marker"
              :google="google"
              :map="map"
            />
            <GoogleMapRoute
              :key="googleMapRouteStateKey"
              :route="route"
              :google="google"
              :map="map"
            />
          </template>
        </GoogleMapLoader>
        <div v-if="activeView === 'grid'">
          <trip-planner-side
            class="filter-sidebar"
            v-if="isMobile && tripPlannerActive"
          />
          <operator-grid v-else />
          <!-- <GoogleMapLoader
            :mapConfig="mapConfig"
            :key="googleMapStateKey"
            apiKey="AIzaSyBgEvk9obqTZvoMB98-BuowWCudtc19mio"
          >
            <template slot-scope="{ google, map }">
              <GoogleMapRoute :route="route" :google="google" :map="map" />
            </template>
          </GoogleMapLoader> -->
        </div>
        <map-operator-side-mobile />
      </div>

      <div>
        <map-itineraries-side
          class="filter-sidebar"
          v-if="
            activeView === 'map' &&
              activeParentType === 'itineraries' &&
              !isMobile &&
              !tripPlannerActive &&
              itinerariesSidebar
          "
        />
        <trip-planner-side
          class="filter-sidebar"
          v-if="activeView === 'map' && !isMobile && tripPlannerActive"
        />
        <div class="filter-sidebar" v-if="sidebarOpen">
          <map-side-footer />
            <div class="footer-sidebar-buttons" v-if="sidebarOpen">
                <!-- <v-btn
                  color="primary"
                  class="trip-planner-button"
                  dark
                  x-large
                  v-if="isMobile"
                  @click="toggleTripPlanner()"
                >
                  My Trip Planner
                </v-btn> -->
                <v-btn
                  color="secondary"
                  class="filter-button"
                  x-large
                  v-if="isMobile"
                  @click="toggleSidebar(false)"
                  >Show results</v-btn
                >
            </div>
        </div>
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-container>
              <v-card-title class="headline">Reset filter?</v-card-title>
              <v-card-text>
                Are you sure you want to reset the filter and show all
                operators?
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="black" text @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" @click="resetData()">
                  Reset filter
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import OperatorGrid from "./OperatorGrid";
import MapSideHeader from "./MapSideHeader";
import MapSideFooter from "./MapSideFooter";
import MapOperatorSideMobile from "./MapOperatorSideMobile";
import MapItinerariesSide from "./MapItinerariesSide";

import GoogleMapLoader from "./GoogleMapLoader";
import GoogleMapMarker from "./GoogleMapMarker";
import GoogleMapRoute from "./GoogleMapRoute";

import TripPlannerSide from "./TripPlannerSide";

import { mapSettings } from "@/constants/mapSettings";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    OperatorGrid,

    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapRoute,

    MapSideHeader,
    MapSideFooter,
    MapOperatorSideMobile,
    MapItinerariesSide,

    TripPlannerSide
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapState([
      "activeParentType",
      "googleMapStateKey",
      "googleMapRouteStateKey",
      "isLoading",
      "tripPlannerActive",
      "itinerariesSidebar"
    ]),
    ...mapGetters(["isMobile"]),
    sidebarOpen: {
      get() {
        return this.$store.state.sidebarOpen;
      },
      set(set) {
        return this.$store.commit("toggleSidebar", {
          referingComponent: this.$options.name,
          set
        });
      }
    },
    activeOperator: {
      get() {
        return this.$store.getters.activeOperator;
      },
      set() {
        return this.$store.commit("updateMarker", null);
      }
    },
    mapConfig() {
      return {
        ...mapSettings
      };
    },
    activeView: {
      get() {
        return this.$store.state.activeView;
      },
      set(value) {
        this.$store.commit("activeView", value);
      }
    },
    markers() {
      return this.$store.getters.operatorMarkers;
    },
    route() {
      return this.$store.getters.routes;
    }
  },
  methods: {
    updateResults() {
      return this.$store.commit("updateResults");
    },
    toggleTripPlanner() {
      this.$store.commit("tripPlannerActive");
    },
    toggleSidebar(set = null) {
      return this.$store.commit("toggleSidebar", set);
    },
    toggleOperator(isActive = null) {
      return this.$store.commit("updateMarker", isActive);
    },
    resetData() {
      this.dialog = false;
      // if (this.confirm === true) {
      return this.$store.commit("clearData");
      // }
      // },
      // filterOperators() {
      //   return this.$store.commit("filterOperators");
    }
  }
};
</script>
<style lang="scss" scoped>
$headerHeight: 68px;
$footerHeight: 48px;

.mobile-content-area {
  // height: calc(100% - #{$headerHeight} - #{$footerHeight});
  height: calc(100% - #{$headerHeight});
  position: absolute;
  // top: $headerHeight;
  right: 0;
  width: 100%;
  overflow: scroll;
  @media screen and (min-width: 768px) {
    width: calc(100% - 420px);
  }
}
.map-mobile-header-container {
  // position: absolute;
  // top: 0;
  // z-index: 2;
  width: 100%;
}
.map-tabs {
  $border-radius: 1em;
  border-radius: $border-radius $border-radius 0 0;
  &__tab {
    color: white !important;
    &--0 {
      border-radius: $border-radius 0 0 0;
    }
    &:last-of-type {
      border-radius: 0 $border-radius 0 0;
    }
    &.active {
      background: white;
      color: black !important;
    }
  }
}
#app {
  .v-navigation-drawer--bottom.v-navigation-drawer {
    max-height: calc(100% - #{$headerHeight});
    margin-bottom: $footerHeight;
    top: $headerHeight !important;
    &.active {
      margin-bottom: 0;
    }
  }
}
.footer-sidebar-buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  // height: $footerHeight;
  z-index: 3;
}
.filter-sidebar {
  position: absolute;
  height: calc(100% - #{$headerHeight});
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.486);
  // border-radius: 1rem 1rem 0 0;
  max-width: 420px;
  width: 100%;
  overflow: auto;

  // button {
  //   width: 100%;
  // }
}
// .is-loading {
//   background: rgba(44, 44, 44, 0.815);
// }
</style>