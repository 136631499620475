<template>
  <div class="trails">
    <v-container>
      <div v-for="trail in trailsData" :key="trail.id">
        <v-switch
          :inset="true"
          color="black"
          :label="decodeHTML(trail.title)"
          :input-value="isActive(trail.trailId)"
          @change="toggleTrail(trail)"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toggleTrail(value) {
      this.$store.commit("updateTrailOptions", value.trailId);
    },
    isActive(id) {
      let active = this.$store.state.selected.trails.filter(trail => {
        return trail === id;
      });
      if (active.length > 0) return true;
      return false;
    },
    decodeHTML(html) {
      let txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }
  },
  computed: {
    trailsData() {
      return this.$store.state.trailsData;
    }
  }
};
</script>