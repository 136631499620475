<template>
  <div>
    <trip-planner-operator-listing
      v-for="(operator, index) in operators"
      :key="index"
      :order="index"
      :operator="operator"
    />
  </div>
</template>
<script>
import TripPlannerOperatorListing from "./TripPlannerOperatorListing";
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedPaginationItem: false,
      paginationItems: "operatorsAll"
    };
  },
  components: {
    TripPlannerOperatorListing
  },
  computed: {
    operators() {
      // if (this.$store.getters.filteredOperators) {
      //   return this.$store.getters.filteredOperators;
      // }

      // return this.$store.state.operatorData;
      let operators = [];
      this.$store.state.operatorData.forEach(operator => {
        let operatorIndex = this.$store.state.tripPlannerData.findIndex(x => {
          return operator.operatorId === x;
        });
        if (operatorIndex !== -1) {
          operators[operatorIndex] = operator;
        }
      });
      return operators;
    },
    selectedItinerary() {
      let selectedItinerary = this.$store.state.selected.itineraries;
      if (selectedItinerary) return true;
      return false;
    },
    ...mapState(["tripPlannerActive", "itinerariesRefreshKey"])
  },
  methods: {
    updatePaginationItem(item) {
      if (this.selectedPaginationItem == item.label) {
        this.selectedPaginationItem = false; //toggle off if clicked on again
        this.pagesTotal = Math.ceil(this.operators.length / this.itemsPerPage);
      } else {
        this.selectedPaginationItem = item.label;
        this.pagesTotal = Math.ceil(item.count / this.itemsPerPage);
      }

      this.pageNumber = 1;
    }
  }
};
</script>
<style lang="scss">
.operator-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 100em;
  margin: 0 auto 4em auto;
  justify-content: center;

  @media screen and (min-width: 768px) {
    &.itineraries {
      margin-top: 60px;
      margin-left: auto;
      position: relative;
      z-index: 9;
    }
  }

  &-operator {
    width: 100%;
    max-width: 24em;
    margin: 1em;
  }
}

.operator-grid-operator {
  background: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}
</style>