<template>
  <div v-if="modalOpen === true" class="favourites-share-modal">
    <div class="favourites-share-modal__inner">
      <div class="close" @click="closeModal">
        <span class="screen-reader-text">close</span>
        <svg xmlns="http://www.w3.org/2000/svg">
          <line
            stroke="#fff"
            stroke-width="1"
            x1="0"
            x2="100%"
            y1="0"
            y2="100%"
          />
          <line
            stroke="#fff"
            stroke-width="1"
            x1="100%"
            x2="0"
            y1="0"
            y2="100%"
          />
        </svg>
      </div>
      <h1 class="share__heading">Share your favourites</h1>
      <div class="share-input">
        <input type="text" :value="fullShareUrl" /><button
          @click="copyShareUrl"
          class="button button--secondary"
          v-text="copyText"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FavouritesShare",
  props: ["modalOpen", "shareUrl"],
  data: function() {
    return {
      copyText: "Copy"
    };
  },
  computed: {
    ...mapState([
      "baseUrl"
    ]),
    fullShareUrl() {
      let operatorIds = this.$store.state.tripPlannerData;
      let shareUrl =
        `${this.baseUrl}/trip-board?share=true`;
      operatorIds.forEach(id => {
        shareUrl += "&ids[]=" + id;
      });
      return shareUrl;
    }
  },
  methods: {
    closeModal: function() {
      this.$emit("updateModal", false);
    },
    copyShareUrl: function() {
      // Copied from https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
      const copyToClipboard = str => {
        const el = document.createElement("textarea"); // Create a <textarea> element
        el.value = str; // Set its value to the string that you want copied
        el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
        el.style.position = "absolute";
        el.style.left = "-9999px"; // Move outside the screen to make it invisible
        document.body.appendChild(el); // Append the <textarea> element to the HTML document
        const selected =
          document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
        el.select(); // Select the <textarea> content
        document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
        document.body.removeChild(el); // Remove the <textarea> element
        if (selected) {
          // If a selection existed before copying
          document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
          document.getSelection().addRange(selected); // Restore the original selection
        }
      };
      copyToClipboard(this.fullShareUrl);
      this.copyText = "Copied!";
    }
  }
};
</script>
<style lang="scss">
$brand-primary: #24b4e9;
.favourites-share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
    margin-top: 0;
  }

  &__inner {
    background: black;
    position: relative;
    width: 80%;
    max-width: 800px;
    padding: 1.75em;
    border-radius: 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  input[type="text"] {
    width: 100%;
    margin-bottom: 1em;
    color: white;
  }

  @media screen and (min-width: 768px) {
    input[type="text"] {
      margin-bottom: 0;
    }

    .button {
      margin-left: 1em;
    }
  }

  .close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    padding: 1em;
    z-index: 2;
    cursor: pointer;

    svg {
      width: 1em;
      height: 1em;

      line {
        stroke-width: 2px;
      }
    }
  }
}

.favourites-share-modal {
  .share__heading {
    color: white;
  }
}
.share-input {
  display: flex;
  flex-direction: column;
  color: white;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}
</style>