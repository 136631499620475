import colorPalette from "@/constants/colorPalette";

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER
};

// const POINT_MARKER_ICON_CONFIG = {
//   path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
//   strokeOpacity: 0.7,
//   strokeWeight: 4,
//   strokeColor: COLORS.POINT,
//   fillColor: COLORS.POINT_FILL,
//   fillOpacity: 1, //0.7,
//   scale: 1
// };

const LINE_SYMBOL_CONFIG = {
  path: "M 0,-2 0,2",
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 1
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: "10px"
    }
  ]
};

const MARKER_LABEL_CONFIG = {
  color: "white",
  fontWeight: "bold"
};

const assetPath = "https://wisdom-plugin-atdw-app.s3-ap-southeast-2.amazonaws.com";
const MARKER_CLUSTER_CONFIG = {
  maxZoom: 14,
  styles: [
    {
      url: `${assetPath}/marker-cluster.svg`,
      height: 40,
      width: 40,
      textColor: "#ffffff",
      textSize: 20
    }
  ]
};

const mapSettings = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: "cooperative",
  backgroundColor: COLORS.LANDSCAPE,
  mapTypeControl: false,
  //   zoomControlOptions: {
  //     style: "SMALL",
  //     position: google.maps.ControlPosition.LEFT_TOP
  //   },
  fullscreenControl: false,
  zoom: 5,
  center: { lat: -34.4217376, lng: 150.8939763 },
  minZoom: 2,
  maxZoom: 16,
  // zoomControl: false,
  styles: [
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ececec"
        },
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#eaeaea"
        }
      ]
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#bcefb1"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#c3eeb5"
        },
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#fff0c2"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#b6b6b6"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#59d5e8"
        }
      ]
    }
  ]
};

export {
  mapSettings,
  assetPath,
  LINE_PATH_CONFIG,
  MARKER_LABEL_CONFIG,
  MARKER_CLUSTER_CONFIG
};
