export const state = {
    cache: true,
    sessionStorageKey: null,
    activeParentType: "directory",
    isDirectoryFilterActive: false,
    activeView: "grid",
    activeMarker: null,
    categories: [],
    sidebarOpen: true,
    tripPlannerActive: false,
    operatorData: [],
    tripPlannerData: [],
    activeCategories: [],
    selected: {
      directory: [],
      trails: [],
      itineraries: null,
      accessibility: [],
      petFriendly: [],
      subcategories: []
    },
    trailsData: [],
    googleMapStateKey: 0,
    googleMapRouteStateKey: 0,
    googleMapMarkerCluster: null,
    itinerariesRefreshKey: 0,
    isLoading: true,
    itinerariesSidebar: false,
    tripPlannerOperators: [],
    tripPlannerPageId: "728",
    disabledFeatures: [
        'facilities'
    ]
}