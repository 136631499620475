<template>
  <div class="map-itineraries-side">
    <v-navigation-drawer :value="true" hide-overlay absolute width="420">
      <map-side-back-button
        text="Back to filter"
        class="back-to-filter"
        @back="setitinerariesSidebar(false)"
      />
      <operator-grid :itineraries="true" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import OperatorGrid from "./OperatorGrid";
import MapSideBackButton from "./MapSideBackButton";
import { mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    OperatorGrid,
    MapSideBackButton
  },
  methods: {
    ...mapMutations(["setitinerariesSidebar"])
  }
};
</script>

<style lang="scss">
.map-operator-side {
  max-width: 500px;
  > * {
    z-index: 4;
  }
}
.map-itineraries-side {
  z-index: 4;

  .back-to-filter {
    position: fixed;
    z-index: 10;
    background: white !important;
    width: 100%;
  }
}
</style>